import { CSSProperties, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModalIconSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import {
  Reservation,
  ResourceReservationGroup as NullableResourceReservationGroup,
  resourceReservationHooks as hooks,
  resourceReservationQueries as queries,
} from '@/modules/Reservations/ResourceReservation'
import { salesHooks } from '@/modules/Sales/hooks'
import { Spacing, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { getDateRange } from '../Sections/GroupManager/utils'
import { Section } from '../types'
import { EditButton, IconButton, IconWrapper, Placeholder } from './common'

type ResourceReservationGroup = Exclude<NullableResourceReservationGroup, null>

type Props = {
  group: NullableResourceReservationGroup
  readOnly: boolean
  reservationId: string
  setCurrentSection: (section: Section) => void
  updateReservations: (reservations: Reservation[]) => void
}

export const Group = ({
  group,
  readOnly,
  reservationId,
  setCurrentSection,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { data } = salesHooks.useSalesDetailsContext()

  const { createGroup, setToGroup } = hooks.useResourceReservationMutations({
    updateReservations,
  })

  const [groupOptions, setGroupOptions] = useState<Option[]>([])
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const groupsRes = useQuery(
    queries.RESOURCE_RESERVATION_GROUPS_BY_SALES_QUERY,
    {
      fetchPolicy: 'no-cache',
      skip: !isEditMode || !data,
      variables: { salesId: data?.id },
    }
  )

  useEffect(() => {
    return () => setProcessing(false)
  }, [])

  useEffect(() => {
    const createNewOption = {
      label: renderCreateNewLabel(),
      value: reservationId,
    }
    const clearSelectionOption = {
      label: renderClearSelectionLabel(),
      value: '',
    }

    const manualOptions = group
      ? [createNewOption, clearSelectionOption]
      : [createNewOption]

    if (groupsRes.data?.reservationGroupsBySales) {
      const options = [
        ...manualOptions,
        ...[...groupsRes.data.reservationGroupsBySales]
          .sort(generateCompareFn('number'))
          .map((g: ResourceReservationGroup) => ({
            label: renderGroupLabel(g),
            value: g.id,
          })),
      ]

      setGroupOptions(options)
    }
  }, [groupsRes, group, reservationId])

  const handleCreateGroup = () => {
    setProcessing(true)

    createGroup(reservationId).then(() => {
      setEditMode(false)
      setHover(false)
      setProcessing(false)
    })
  }

  const handleSetToGroup = (groupId: string | null) => {
    setProcessing(true)

    setToGroup(reservationId, groupId).then(() => {
      setEditMode(false)
      setHover(false)
      setProcessing(false)
    })
  }

  return (
    <InlineModalIconSection icon="user-group">
      <FlexRow>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={isProcessing}
            menuIsOpen={!groupsRes.loading && isEditMode}
            name="reservation-group-selector"
            onBlur={() => {
              setEditMode(false)
              setHover(false)
            }}
            onChange={(option?: Option | null) =>
              option?.value === reservationId
                ? handleCreateGroup()
                : handleSetToGroup(option ? option.value : null)
            }
            options={groupOptions}
            placeholder={
              <Placeholder>
                <T>ReservationModal:group.addToGroup</T>
              </Placeholder>
            }
            value={
              group
                ? {
                    label: renderGroupLabel(group),
                    value: group.id,
                  }
                : null
            }
          />
        ) : (
          <EditButton
            disabled={readOnly}
            onClick={() => setEditMode(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {group ? (
              renderGroupLabel(group)
            ) : (
              <Placeholder>
                <T>ReservationModal:group.addToGroup</T>
              </Placeholder>
            )}

            {!readOnly && (
              <IconWrapper>
                {isHover && (
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon={group ? 'retweet' : 'plus'}
                    size="sm"
                  />
                )}
              </IconWrapper>
            )}
          </EditButton>
        )}

        {!readOnly && group && (
          <Tooltip
            content={<T>ReservationModal:group.openGroupManager</T>}
            delay={300}
            trigger={(triggerProps) => (
              <IconButton
                {...triggerProps}
                disabled={readOnly}
                onClick={() => setCurrentSection({ type: 'GROUP_MANAGER' })}
                style={{ marginLeft: `${spacing.gu(1)}rem` }}
              >
                <FontAwesomeIcon
                  color={palette.primary.main}
                  icon="pen"
                  size="sm"
                />
              </IconButton>
            )}
          />
        )}
      </FlexRow>
    </InlineModalIconSection>
  )
}

////////////

const renderGroupLabel = ({
  end,
  name,
  number,
  start,
}: ResourceReservationGroup) => (
  <FlexRow alignItems="center" flex={1} justifyContent="space-between">
    <GroupName>
      #{number} {name || <T>ReservationModal:group.title</T>}
    </GroupName>
    <GroupDateRange>{getDateRange(end, start)}</GroupDateRange>
  </FlexRow>
)

const renderCreateNewLabel = () => (
  <CreateNewLabel>
    <T>ReservationModal:group.createNew</T>
  </CreateNewLabel>
)

const renderClearSelectionLabel = () => (
  <ClearSelectionLabel>
    <T>Reactivesearch:clearSelection</T>
  </ClearSelectionLabel>
)

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor:
      styles.backgroundColor === 'transparent' ? 'not-allowed' : 'pointer',
    lineHeight: '24px',
    padding: '4px 38px 4px 8px',
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    maxWidth: 'calc(100% - 18px)',
    width: '100%',
  }),
})

const ClearSelectionLabel = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}
`

const CreateNewLabel = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`

const GroupName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(20)}rem;
  `}
`

const GroupDateRange = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
