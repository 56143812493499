import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { ListItemSectionIconValue as IconValue } from '@/components/List'
import { Tooltip } from '@/components/Tooltip'
import { P } from '@/components/Typography'
import { T } from '@/modules/Language'
import { CalendarResourceElastic } from '@/modules/Registry/CalendarResources'
import { generateCompareFn } from '@/utils/arrays'

import { CalendarResourceTypeChip } from './CalendarResourceTypeChip'

type Props = {
  resource: CalendarResourceElastic
}

export const ResourceDisplayTooltip = ({
  resource: { categories, floorSize, id, name, personCapacity, type },
}: Props) => (
  <Tooltip
    content={
      <FlexColumn alignItems="flex-start" noPadding>
        <CalendarResourceTypeChip size="xs" variant="outlined" type={type} />

        <TooltipLabel>{name}</TooltipLabel>

        <IconValue
          icon="diagram-project"
          style={{ width: '100%' }}
          value={
            <CategoriesWrapper alignItems="flex-start">
              {categories.length ? (
                categories
                  .sort(generateCompareFn('name'))
                  .map(({ name }) => name)
                  .filter(Boolean)
                  .map((name) => (
                    <Value key={`resource-category-${id}-${name}`}>
                      {name}
                    </Value>
                  ))
              ) : (
                <span>—</span>
              )}
            </CategoriesWrapper>
          }
        />

        {floorSize && (
          <IconValue
            icon="square-full"
            style={{ width: '100%' }}
            value={
              <Value>
                {floorSize} m<sup>2</sup>
              </Value>
            }
          />
        )}

        {personCapacity && (
          <IconValue
            icon="users"
            style={{ width: '100%' }}
            value={
              <Value>
                {personCapacity} <T>common:unit.PERSON</T>
              </Value>
            }
          />
        )}
      </FlexColumn>
    }
    delay={300}
    placement="right"
    trigger={(triggerProps) => (
      <span {...triggerProps}>
        <InfoIcon icon="circle-info" size="lg" />
      </span>
    )}
  />
)

////////////

const CategoriesWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const InfoIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }
`

const TooltipLabel = styled(P)`
  font-weight: 500;

  ${({ theme }) => css`
    margin: ${theme.spacing.gutterSmall} 0;
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const Value = styled.span`
  text-align: start;
  width: 100%;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterSmall};
  `}
`
