import { gql } from '@apollo/client'

export default gql`
  fragment SalesLifecycle on SalesLifecycle {
    isConfirmed
    reason
    specifier
    state {
      isConfirmed
      key
      names {
        locale
        content
      }
      processOrder
      disabled
      systemState
    }
  }
`
