import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { orderServices } from '@/modules/Order/services'
import {
  Payment,
  PaymentDetails,
  PaymentOtherDetails,
  PaymentVoucherDetails,
} from '@/modules/Order/types'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import { PaymentOperation, PaymentType } from '~generated-types'

type Props = {
  invoiceId: string
  orderId: string
  payment: Payment
}

export const PaymentRow = ({ invoiceId, orderId, payment }: Props) => {
  const { palette } = useTheme()
  const { amount, details, id, lifecycle, type } = payment

  const { removeManualPayment } = orderServices.invoiceService()

  const [processing, setProcessing] = useState<boolean>(false)

  const handleRemovePayment = () => {
    setProcessing(true)

    removeManualPayment(invoiceId, orderId, id).then(
      (data) => !data && setProcessing(false)
    )
  }

  const readOnly = !lifecycle.validatedActions.find(
    ({ action }) => action === PaymentOperation.DeleteManualPayment
  )?.valid

  return (
    <Wrapper>
      <span>{getPaymentTypeLabel(type, details)}</span>
      <Separator />
      <span>{formatCurrency(amount)} €</span>

      {!readOnly && (
        <RemoveButtonWrapper>
          {processing ? (
            <LoaderWrapper>
              <ReactLoading
                color={palette.smoke.main}
                height={20}
                type="spin"
                width={20}
              />
            </LoaderWrapper>
          ) : (
            <RemoveButton noMargin noNudge onClick={handleRemovePayment}>
              <FontAwesomeIcon color={palette.danger.dark} icon="xmark" />
            </RemoveButton>
          )}
        </RemoveButtonWrapper>
      )}
    </Wrapper>
  )
}
///////

const getPaymentTypeLabel = (type: PaymentType, details: PaymentDetails) => {
  switch (type) {
    case PaymentType.Other:
      return (
        (details as PaymentOtherDetails).paymentType?.name ?? (
          <T>Orders:Payments.type.OTHER</T>
        )
      )
    case PaymentType.Voucher:
      return (details as PaymentVoucherDetails).type.name
    default:
      return <T>{`Orders:Payments.type.${type}`}</T>
  }
}

const LoaderWrapper = styled.div`
  padding: 2px;
`

const RemoveButton = styled(InnocuousButton)`
  height: 24px;
  width: 24px;
`

const RemoveButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
    margin-right: -${theme.spacing.gu(1)}rem;
  `}
`

const Separator = styled.span`
  flex: 1;

  ${({ theme }) => css`
    border-top: 1px dashed ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gutter};
  `}
`

const Wrapper = styled(FlexRow)`
  align-items: center;
  font-weight: 500;
  height: 24px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
