import { Ref, useEffect, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useRoomLayoutContext } from '@/modules/Reservations/components/RoomLayout'
import { useGlobalIconsContext } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { ControlRowButton } from './ControlRowButton'
import { FilterWrapper } from './FilterWrapper'

type Props = {
  targetFeatureIds: string[]
  setTargetFeatureIds: (ids: string[]) => void
}

type Feature = {
  icon: string | null
  id: string
  name: string
  selected: boolean
  shortName: string
}

export const Features = ({ targetFeatureIds, setTargetFeatureIds }: Props) => {
  const { roomFeatures } = useRoomLayoutContext()
  const { checkIconValidity } = useGlobalIconsContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [features, setFeatures] = useState<Feature[]>([])

  useEffect(() => {
    setFeatures(
      roomFeatures.map((feature) => ({
        icon: feature.icon,
        id: feature.id,
        name: feature.name,
        selected: targetFeatureIds.includes(feature.id),
        shortName: feature.shortName,
      }))
    )
  }, [roomFeatures, targetFeatureIds])

  const handleUpdateFeatures = (currentFeatureId: string) => {
    const updatedFeatures = features.map((f) =>
      f.id === currentFeatureId ? { ...f, selected: !f.selected } : f
    )

    setFeatures(updatedFeatures)
    setTargetFeatureIds(
      updatedFeatures.filter(({ selected }) => selected).map(({ id }) => id)
    )
  }

  const renderFeatureButton = (feature: Feature, forModal?: boolean) => (
    <Tooltip
      content={feature.name}
      delay={300}
      key={feature.id}
      placement="top"
      trigger={(triggerProps) => (
        <ControlRowButton
          {...triggerProps}
          fixedIconWidth
          forModal={forModal}
          onClick={() => handleUpdateFeatures(feature.id)}
          isSelected={feature.selected}
        >
          {checkIconValidity(feature.icon) ? (
            <FontAwesomeIcon icon={feature.icon as IconProp} />
          ) : (
            feature.shortName
          )}
        </ControlRowButton>
      )}
    />
  )

  return !!features.length ? (
    <FilterWrapper label={<T>RoomLayout:controlRow.label.features</T>}>
      <FlexRow>
        {features
          .sort(generateCompareFn('name'))
          .filter((feature: Feature) => feature.selected)
          .map((feature: Feature) => renderFeatureButton(feature))}
        <ModalContainer
          isOpen={isModalOpen}
          modal={
            <InlineModal style={{ width: '155px' }}>
              <ModalSection>
                <FlexRow
                  alignItems="center"
                  justifyContent="space-between"
                  wrap="wrap"
                >
                  {[...features]
                    .sort(generateCompareFn('name'))
                    .map((feature: Feature) =>
                      renderFeatureButton(feature, true)
                    )}
                </FlexRow>
              </ModalSection>
            </InlineModal>
          }
          onClose={() => setModalOpen(false)}
          placement="bottom-end"
          referenceElement={({ ref }) => (
            <ControlRowButton
              fixedIconWidth
              onClick={() => setModalOpen(true)}
              ref={ref as Ref<HTMLButtonElement> | undefined}
            >
              <FontAwesomeIcon icon="plus" size="sm" />
            </ControlRowButton>
          )}
        />
      </FlexRow>
    </FilterWrapper>
  ) : null
}

////////

export const ModalSection = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gu(1)}rem;
  `}
`
