import { MouseEvent, Ref } from 'react'

import { FlexColumn } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'

import { OrderPhaseType as OPT } from '~generated-types'

import { ActionButton } from './ActionButton'
import { ActionPlaceholder } from './ActionPlaceholder'
import { ValidatedActionType } from './types'

type Props = {
  innerRef?: Ref<HTMLDivElement>
  isDisabled?: boolean
  isProcessing?: boolean
  onClick: (e: MouseEvent) => void
  phase: OPT | 'ORDER'
  validatedAction: ValidatedActionType
}

export const Action = ({
  innerRef,
  isDisabled,
  isProcessing,
  onClick,
  phase: orderPhase,
  validatedAction: { action, issues, valid },
}: Props) => {
  const phase =
    orderPhase === OPT.InvoiceRefund ||
    orderPhase === OPT.InvoiceReservationFee ||
    orderPhase === OPT.InvoiceReservationFeeRefund
      ? OPT.Invoice
      : orderPhase

  return valid ? (
    <ActionButton
      action={action}
      innerRef={innerRef as Ref<HTMLButtonElement> | undefined}
      isDisabled={isDisabled}
      isProcessing={isProcessing}
      label={<T>{`Orders:Action.${phase}.${action}`}</T>}
      onClick={onClick}
    />
  ) : (
    <Tooltip
      content={
        <FlexColumn noPadding alignItems="flex-start">
          {issues.map(({ code }) => (
            <span key={`issue-code-${code}`}>
              <T>{`IssueCodes:${code}`}</T>
            </span>
          ))}
        </FlexColumn>
      }
      delay={300}
      key={action}
      maxWidth={150}
      placement="top"
      trigger={({ ref, ...rest }) => (
        <ActionPlaceholder
          action={action}
          innerRef={ref}
          label={<T>{`Orders:Action.${phase}.${action}`}</T>}
          {...rest}
        />
      )}
    />
  )
}
