import CONFIRMATION_BY_ID from './confirmationById'
import INVOICE_BY_ID from './invoiceById'
import OFFER_BY_ID from './offerById'
import ORDER_LIFECYCLE from './orderLifecycle'
import ORDERS from './orders'
import OTHER_PAYMENT_TYPES from './otherPaymentTypes'
import PAYMENT_TERMINALS from './paymentTerminals'
import POINTS_OF_SALES from './pointsOfSales'
import VOUCHER_PROVIDERS from './voucherProviders'

export const orderQueries = Object.freeze({
  CONFIRMATION_BY_ID,
  INVOICE_BY_ID,
  OFFER_BY_ID,
  ORDER_LIFECYCLE,
  ORDERS,
  OTHER_PAYMENT_TYPES,
  PAYMENT_TERMINALS,
  POINTS_OF_SALES,
  VOUCHER_PROVIDERS,
})
