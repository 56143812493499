import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { FlexColumn } from '@/components/Layout'
import { PaymentsModal } from '@/modules/Order/components'
import {
  ObservablePaymentProvider,
  orderContexts,
} from '@/modules/Order/contexts'
import { orderQueries } from '@/modules/Order/queries'
import {
  Invoice,
  InvoiceByIdPayload,
  InvoiceByIdVariables,
  InvoiceInfo,
  OrderType as Order,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales/hooks'

import { CollapseWrapper, ContentWrapper } from '../common'
import { Content, Header } from './components'

type Props = {
  invoice: Invoice | InvoiceInfo
  isLastNested?: boolean
  isNested?: boolean
  order: Order
}

export const InvoicePhase = ({
  invoice,
  isLastNested,
  isNested,
  order,
}: Props) => {
  const { id } = invoice

  const { selectedPhaseId, setSelectedPhaseId } = orderContexts.useOrderView()
  const { setOrder } = salesHooks.useSalesDetailsContext()

  const invoiceFull = invoice as Invoice
  const isFull = invoiceFull.document !== undefined
  const isOpen = selectedPhaseId === id

  const [isContentFetched, setContentFetched] = useState<boolean>(false)
  const [isPaymentsModalOpen, setPaymentsModalOpen] = useState<boolean>(false)

  const { loading, refetch } = useQuery<
    InvoiceByIdPayload,
    InvoiceByIdVariables
  >(orderQueries.INVOICE_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ invoice }) => {
      setOrder({
        ...order,
        invoices: order.invoices.map((i) => (i.id === id ? invoice : i)),
      })
      setContentFetched(true)
    },
    skip: !isOpen,
    variables: { id },
  })

  const toggleContent = () => {
    if (isOpen) {
      setSelectedPhaseId(null)
      setContentFetched(false)
    } else {
      setSelectedPhaseId(id)
    }
  }

  return (
    <FlexColumn>
      <ObservablePaymentProvider>
        <Header
          isOpen={isOpen && isContentFetched}
          isLastNested={isLastNested}
          isLoading={loading}
          isNested={isNested}
          invoice={invoice}
          openPaymentsModal={() => setPaymentsModalOpen(true)}
          orderId={order.id}
          toggleContent={toggleContent}
        />

        <CollapseWrapper isOpen={isOpen && isContentFetched}>
          <FlexColumn>
            {isFull && (
              <ContentWrapper>
                <Content
                  invoice={invoiceFull}
                  isVisible={isOpen}
                  openPaymentsModal={() => setPaymentsModalOpen(true)}
                  order={order}
                  refetch={refetch}
                />
              </ContentWrapper>
            )}
          </FlexColumn>
        </CollapseWrapper>

        {isPaymentsModalOpen && invoiceFull.document && (
          <PaymentsModal
            documentId={invoiceFull.document.id}
            invoice={invoiceFull}
            onClose={() => setPaymentsModalOpen(false)}
            orderId={order.id}
          />
        )}
      </ObservablePaymentProvider>
    </FlexColumn>
  )
}
