import { gql } from '@apollo/client'

import { orderFragments } from '../fragments'

export default gql`
  ${orderFragments.confirmationInfo}
  ${orderFragments.invoiceInfo}
  ${orderFragments.offerInfo}
  ${orderFragments.orderShortInfo}

  subscription Order($filter: OrderFilterInput!) {
    order(filter: $filter) {
      orderId
      salesId
      event {
        ... on OrderEvent {
          eventData: data {
            ...OrderShortInfo
          }
          id
          type
        }
        ... on OrderConfirmationEvent {
          confirmationData: data {
            ...ConfirmationInfo
          }
          id
          type
        }
        ... on OrderOfferEvent {
          offerData: data {
            ...OfferInfo
          }
          id
          type
        }
        ... on OrderInvoiceEvent {
          invoiceData: data {
            ...InvoiceInfo
          }
          id
          type
        }
        ... on OrderItemEvent {
          itemData: data {
            ... on OrderPacket {
              id
            }
            ... on OrderProduct {
              id
            }
          }
          id
          type
        }
      }
    }
  }
`
