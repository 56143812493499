import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { FlexRow } from '@/components/Layout'
import { ReactiveBase } from '@/components/Reactivesearch'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { CategorySelection } from '@/modules/Reservations'
import { ElasticBaseUrl } from '@/utils/api'

import CategorySearchGroup from './CategorySearchGroup'
import IconButton from './SelectorIconButton'

type Props = {
  add: () => CategorySelection
  data: CategorySelection[]
  disabled?: boolean
  remove: (groupId: string) => void
  update: (
    groupId: string,
    updateContent: {
      [key: string]: any
    }
  ) => void
}

export const CategorySearchGroups = ({
  add,
  data,
  disabled,
  remove,
  update,
}: Props) => {
  const [addedGroupIds, setAddedGroupIds] = useState<string[]>([])

  return (
    <FilterSection
      label={
        <FlexRow alignItems="center" justifyContent="space-between">
          <T>ResourceReservationsCalendar:CategorySelector.title</T>
          <IconButton
            disabled={disabled}
            onClick={() => {
              const group = add()
              setAddedGroupIds((current) => [...current, group.id])
            }}
          >
            <Tooltip
              content={<T>ResourceReservationsCalendar:CategorySelector.add</T>}
              delay={300}
              placement="left"
              trigger={(triggerProps) => (
                <span {...triggerProps}>
                  <FontAwesomeIcon icon="plus" />
                </span>
              )}
            />
          </IconButton>
        </FlexRow>
      }
      render={() => (
        <ReactiveBase app="registry.resource" url={ElasticBaseUrl}>
          {data.map((group, idx) => (
            <CategorySearchGroup
              data={group}
              disabled={disabled}
              expandInitially={idx === 0 || addedGroupIds.includes(group.id)}
              idx={idx}
              key={`category-search-group-${group.id}`}
              remove={() => remove(group.id)}
              update={(updateContent: { [key: string]: any }) =>
                update(group.id, updateContent)
              }
            />
          ))}
        </ReactiveBase>
      )}
    />
  )
}
