import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  message: string
}

export const DisplayMessageNotification = ({ message }: Props) => {
  const { palette } = useTheme()

  return (
    <Tooltip
      content={
        <FlexColumn alignItems="flex-start" noPadding>
          <span style={{ fontWeight: 500 }}>
            <T>ReservationModal:fields.roomMessage.label</T>
          </span>
          <span style={{ fontWeight: 300, marginTop: '3px' }}>{message}</span>
        </FlexColumn>
      }
      delay={300}
      placement="top"
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <FontAwesomeIcon
            color={palette.warning.dark}
            icon="display"
            size="sm"
          />
        </div>
      )}
    />
  )
}
