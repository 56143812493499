import { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { orderQueries } from '@/modules/Order/queries'
import { VoucherProvidersQuery } from '@/modules/Order/types'
import { useTheme } from '@/theme'

import { PaymentType as PaymentMethod } from '~generated-types'

import { PaymentType } from '../../../types'
import {
  Dropdown,
  DropdownArrowButton,
  DropdownButton,
  DropdownWrapper,
  Icon,
  Label,
  LabelTruncate,
  OptionButton,
} from '../common'

type VoucherProvider = VoucherProvidersQuery['registry']['voucherProviders'][0]

type Props = {
  paymentType: PaymentType | null
  setPaymentType: (paymentType: PaymentType | null) => void
}

export const VoucherPaymentButton = ({
  paymentType,
  setPaymentType,
}: Props) => {
  const { palette } = useTheme()

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [modalWidth, setModalWidth] = useState<number>(0)
  const [providers, setProviders] = useState<VoucherProvider[]>([])
  const [selectedProvider, setSelectedProvider] =
    useState<VoucherProvider | null>(null)

  const { data, loading } = useQuery<VoucherProvidersQuery>(
    orderQueries.VOUCHER_PROVIDERS
  )

  useEffect(() => {
    if (data) {
      setProviders(data.registry.voucherProviders)
    }
  }, [data])

  useEffect(() => {
    if (wrapperRef.current?.clientWidth) {
      setModalWidth(wrapperRef.current.clientWidth + 1)
    }
  }, [wrapperRef.current?.clientWidth])

  const handleSetPaymentType = () =>
    !isPaymentTypeSelected
      ? selectedProvider
        ? setPaymentType({
            method: PaymentMethod.Voucher,
            providerId: selectedProvider.id,
          })
        : setModalOpen(true)
      : setPaymentType(null)

  const handleSelectProvider = (provider: VoucherProvider) => {
    setSelectedProvider(provider)
    setPaymentType({ method: PaymentMethod.Voucher, providerId: provider.id })
    setModalOpen(false)
  }

  const isPaymentTypeSelected = paymentType?.method === PaymentMethod.Voucher

  return (
    <DropdownWrapper ref={wrapperRef}>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <Modal modalWidth={modalWidth}>
            <FlexColumn noPadding>
              {providers.map((p) => (
                <OptionButton
                  isSelected={selectedProvider?.id === p.id}
                  key={`voucher-provider-${p.id}`}
                  onClick={() => handleSelectProvider(p)}
                >
                  {p.name}
                </OptionButton>
              ))}
            </FlexColumn>
          </Modal>
        }
        onClose={() => setModalOpen(false)}
        placement="top-end"
        referenceElement={({ ref }) => (
          <Dropdown ref={ref} isSelected={isPaymentTypeSelected}>
            <DropdownButton
              isSelected={isPaymentTypeSelected}
              onClick={handleSetPaymentType}
            >
              <Icon fixedWidth icon="money-check-dollar" />
              <FlexColumn noPadding alignItems="flex-start">
                <Label>
                  <T>Orders:Payments.type.VOUCHER</T>
                </Label>

                {selectedProvider && (
                  <LabelTruncate title={selectedProvider.name}>
                    {selectedProvider.name}
                  </LabelTruncate>
                )}
              </FlexColumn>
            </DropdownButton>

            <DropdownArrowButton
              isSelected={isPaymentTypeSelected}
              onClick={() => setModalOpen(true)}
            >
              {loading ? (
                <ReactLoading
                  color={palette.smoke.dark}
                  height={18}
                  type="spin"
                  width={18}
                />
              ) : (
                <FontAwesomeIcon icon="chevron-down" />
              )}
            </DropdownArrowButton>
          </Dropdown>
        )}
      />
    </DropdownWrapper>
  )
}

/////////

const Modal = styled(InlineModal)<{ modalWidth: number }>`
  ${({ theme, modalWidth }) => css`
    padding: ${theme.spacing.gu(0.5)}rem 0;
    min-width: ${modalWidth}px;
    max-height: ${theme.spacing.gu(33)}rem;
  `}
`
