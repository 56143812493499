import { gql, useQuery } from '@apollo/client'
import { Decimal } from 'decimal.js'
import { Moment } from 'moment'

import { generateCompareFn } from '@/utils/arrays'

import {
  PaymentType,
  UsePaymentsForIntervalPointOfSaleFragment,
  UsePaymentsForIntervalQuery as QueryData,
  UsePaymentsForIntervalQueryVariables as QueryVariables,
} from '~generated-types'

export type Payment = QueryData['payments']['payments'][0]
export type Invoice = QueryData['payments']['payments'][0]['invoice']
export type PointOfSale = UsePaymentsForIntervalPointOfSaleFragment

export const SUPPORTED_TYPES = Object.freeze([
  PaymentType.Cash,
  PaymentType.CreditCard,
  PaymentType.GiftCard,
  PaymentType.Voucher,
])

export const QUERY = gql`
  fragment UsePaymentsForIntervalPointOfSale on PointOfSale {
    id
    name
  }

  query UsePaymentsForInterval($input: PaymentsInput!) {
    payments(input: $input) {
      payments {
        amount
        auditLog {
          createdAt
          # TODO: Coming later
          # createdBy
        }
        details {
          ... on PaymentCashDetails {
            notes
            pointOfSale {
              ...UsePaymentsForIntervalPointOfSale
            }
          }

          ... on PaymentCreditCardDetails {
            notes
            pointOfSale {
              ...UsePaymentsForIntervalPointOfSale
            }
          }

          ... on PaymentGiftCardDetails {
            notes
            pointOfSale {
              ...UsePaymentsForIntervalPointOfSale
            }
          }

          ... on PaymentVoucherDetails {
            notes
            pointOfSale {
              ...UsePaymentsForIntervalPointOfSale
            }
            type {
              id
              name
            }
          }
        }
        id
        invoice {
          customer {
            customerNumber
            name
          }
          id
          invoiceNumber
          order {
            sales {
              facet {
                abbreviation
                color
                id
                name
              }
              id
              name
              orderNumber
              type
            }
          }
          seller {
            id
            name
            shortName
          }
        }
        type
      }
    }
  }
`

export interface NotesByType {
  [PaymentType.Cash]: string | null
  [PaymentType.CreditCard]: string | null
  [PaymentType.GiftCard]: string | null
  vouchersByProvider: Record<string, string | null>
}

export interface PaymentAmountsByType {
  [PaymentType.Cash]: Decimal
  [PaymentType.CreditCard]: Decimal
  [PaymentType.GiftCard]: Decimal
  vouchersByProvider: Record<string, Decimal>
}

export interface InvoiceDetails {
  amounts: PaymentAmountsByType
  invoice: NonNullable<Invoice>
  notes: NotesByType
}

export interface PointOfSaleDetails {
  amounts: PaymentAmountsByType
  amountsTotal: Decimal
  invoicesById: Record<string, InvoiceDetails>
  pointOfSale: PointOfSale
}

export interface UsePaymentsForIntervalParams {
  from: Moment
  to: Moment
  types: PaymentType[]
}

interface UsePaymentsForIntervalHook {
  allData: Payment[]
  error: boolean
  groupedData: PointOfSaleDetails[]
  loading: boolean
}

export default function usePaymentsForInterval({
  from,
  to,
  types,
}: UsePaymentsForIntervalParams): UsePaymentsForIntervalHook {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        from: from.toISOString(),
        to: to.toISOString(),
        types,
      },
    },
  })

  const sortedPayments: Payment[] = [...(data?.payments.payments || [])].sort(
    generateCompareFn('auditLog.createdAt')
  )

  const groupedPayments: Record<string, PointOfSaleDetails> =
    sortedPayments.reduce((acc: Record<string, PointOfSaleDetails>, val) => {
      const { amount, details, id, invoice, type } = val
      const pointOfSale = getPointOfSale(val)

      if (!!invoice && !!pointOfSale) {
        acc[pointOfSale.id] = acc[pointOfSale.id] || {
          amounts: getEmptyAmounts(),
          amountsTotal: new Decimal(0),
          invoicesById: {},
          pointOfSale,
        }

        acc[pointOfSale.id].invoicesById[invoice.id] = acc[pointOfSale.id]
          .invoicesById[invoice.id] || {
          amounts: getEmptyAmounts(),
          invoice,
          notes: getEmptyNotes(),
        }

        if (
          type === PaymentType.Cash ||
          type === PaymentType.CreditCard ||
          type === PaymentType.GiftCard
        ) {
          acc[pointOfSale.id].amounts[type] =
            acc[pointOfSale.id].amounts[type].plus(amount)
          acc[pointOfSale.id].amountsTotal =
            acc[pointOfSale.id].amountsTotal.plus(amount)
          acc[pointOfSale.id].invoicesById[invoice.id].amounts[type] =
            acc[pointOfSale.id].invoicesById[invoice.id].amounts[type].plus(
              amount
            )

          acc[pointOfSale.id].invoicesById[invoice.id].notes = {
            ...acc[pointOfSale.id].invoicesById[invoice.id].notes,
            [type]: getNotes(val),
          }
        } else if (
          type === PaymentType.Voucher &&
          details &&
          details.__typename === 'PaymentVoucherDetails'
        ) {
          acc[pointOfSale.id].amounts.vouchersByProvider[details.type.name] = (
            acc[pointOfSale.id].amounts.vouchersByProvider[details.type.name] ||
            new Decimal(0)
          ).plus(amount)
          acc[pointOfSale.id].amountsTotal =
            acc[pointOfSale.id].amountsTotal.plus(amount)
          acc[pointOfSale.id].invoicesById[
            invoice.id
          ].amounts.vouchersByProvider[details.type.name] = (
            acc[pointOfSale.id].invoicesById[invoice.id].amounts
              .vouchersByProvider[details.type.name] || new Decimal(0)
          ).plus(amount)

          const voucherNotes =
            acc[pointOfSale.id].invoicesById[invoice.id].notes
              .vouchersByProvider

          acc[pointOfSale.id].invoicesById[invoice.id].notes = {
            ...acc[pointOfSale.id].invoicesById[invoice.id].notes,
            vouchersByProvider: {
              ...voucherNotes,
              [details.type.name]: getNotes(val),
            },
          }
        } else {
          console.warn(`Unsupported payment type [id=${type}]`)
        }
      } else {
        console.warn(
          `Missing invoice or point of sale details from payment [id=${id}]`
        )
      }

      return acc
    }, {})

  return {
    allData: sortedPayments,
    error: !!error,
    groupedData: Object.values(groupedPayments).sort(
      generateCompareFn('pointOfSale.name')
    ),
    loading,
  }
}

////////////

function getEmptyAmounts(): PaymentAmountsByType {
  return {
    [PaymentType.Cash]: new Decimal(0),
    [PaymentType.CreditCard]: new Decimal(0),
    [PaymentType.GiftCard]: new Decimal(0),
    vouchersByProvider: {},
  }
}

function getEmptyNotes(): NotesByType {
  return {
    [PaymentType.Cash]: null,
    [PaymentType.CreditCard]: null,
    [PaymentType.GiftCard]: null,
    vouchersByProvider: {},
  }
}

function getNotes(input: Payment): string | null {
  if (
    input.details?.__typename === 'PaymentCashDetails' ||
    input.details?.__typename === 'PaymentCreditCardDetails' ||
    input.details?.__typename === 'PaymentGiftCardDetails' ||
    input.details?.__typename === 'PaymentVoucherDetails'
  ) {
    return input.details?.notes || null
  }

  return null
}

function getPointOfSale(input: Payment): PointOfSale | null {
  if (
    input.details?.__typename === 'PaymentCashDetails' ||
    input.details?.__typename === 'PaymentCreditCardDetails' ||
    input.details?.__typename === 'PaymentGiftCardDetails' ||
    input.details?.__typename === 'PaymentVoucherDetails'
  ) {
    return input.details?.pointOfSale || null
  }

  return null
}
