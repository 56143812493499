import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { ActionDropdown, ActionDropdownItem } from '@/components/ActionDropdown'
import { IconButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { SalesTypeChip } from '@/modules/Sales'
import { useTheme } from '@/theme'

import { InvoiceDetails } from '../usePaymentsForInterval'
import { PaymentCells } from './PaymentCells'
import { ProductLinesTable } from './ProductLinesTable'
import { ReceiptModal } from './ReceiptModal'
import { StyledLink } from './StyledLink'

export interface PaymentsTableRowProps {
  colSpan: number
  data: InvoiceDetails
  voucherProviders: string[]
}

export const PaymentsTableRow = ({
  colSpan,
  data: { amounts, invoice, notes },
  voucherProviders,
}: PaymentsTableRowProps) => {
  const theme = useTheme()

  const [showProductLines, setShowProductLines] = useState<boolean>(false)
  const [showReceipt, setShowReceipt] = useState<boolean>(false)

  const sales = invoice.order.sales

  const rowActions: ActionDropdownItem[] = [
    {
      icon: 'receipt',
      label: <T>Reports:Payments.action.showReceipt</T>,
      onClick: () => setShowReceipt(true),
    },
    {
      icon: 'file-invoice-dollar',
      label: <T>Reports:Payments.action.showProductLines</T>,
      onClick: () => setShowProductLines((current) => !current),
    },
  ]

  return (
    <>
      <tr>
        <td>{invoice.invoiceNumber || '—'}</td>
        <PaymentCells
          amounts={amounts}
          notes={notes}
          voucherProviders={voucherProviders}
        />
        <td className="show-left-border">{invoice.seller?.shortName || '—'}</td>
        <td>
          <span>{invoice.customer?.name || '—'}</span>
        </td>
        <td>
          <FlexRow alignItems="baseline" justifyContent="space-between">
            <StyledLink to={`/sales/details/${sales.id}`}>
              {sales.orderNumber && `#${sales.orderNumber} `}
              {sales.name && sales.orderNumber && '– '}
              {sales.name}
            </StyledLink>
            <SalesTypeChip
              facet={sales.facet}
              format="short"
              size="xs"
              style={{ marginLeft: theme.spacing.gutter }}
              type={sales.type}
            />
          </FlexRow>
        </td>
        <ActionButtonTD>
          <ActionDropdown
            items={rowActions}
            renderToggle={({ toggleDropdown }) => (
              <IconButton
                color="transparent"
                icon="ellipsis-vertical"
                onClick={toggleDropdown}
              />
            )}
          />
        </ActionButtonTD>
      </tr>
      {showProductLines && (
        <ProductLinesWrapper>
          <td />
          <td colSpan={colSpan - 2}>
            <ProductLinesTable invoiceId={invoice.id} />
          </td>
          <ActionButtonTD>
            <IconButton
              color="transparent"
              icon="xmark"
              onClick={() => setShowProductLines(false)}
            />
          </ActionButtonTD>
        </ProductLinesWrapper>
      )}
      {showReceipt && (
        <ReceiptModal
          invoiceId={invoice.id}
          showReceipt={showReceipt}
          onClose={() => setShowReceipt(false)}
        />
      )}
    </>
  )
}

export default PaymentsTableRow

////////////

const ActionButtonTD = styled.td`
  &&& {
    padding-bottom: 0;

    ${({ theme }) => css`
      padding-top: ${theme.spacing.gu(0.5)}rem;
    `}
  }

  @media print {
    &&& {
      border-right-width: 0;
      padding-left: 0;
      padding-right: 0;
      width: 0;
    }

    & * {
      display: none;
    }
  }
`

const ProductLinesWrapper = styled.tr`
  @media print {
    display: none;
  }
`
