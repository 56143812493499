import { CSSProperties, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OptionProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import type {
  Reservation,
  ResourceReservationDimension as DimensionType,
  ResourceReservationDimensionOption as DimensionOptionType,
} from '@/modules/Reservations/ResourceReservation'
import { resourceReservationHooks } from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'

import { EditButton, Placeholder } from '../common'

type Props = {
  dimension: DimensionType
  dimensions: DimensionType[]
  readOnly: boolean
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
}

export const Dimension = ({
  dimension,
  dimensions,
  readOnly,
  reservationId,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { setDimensions } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const onSetLabel = (selectedOption: Option | null | undefined) => {
    setProcessing(true)

    const selectedLabel = dimension.validatedOptions.find(
      (option: DimensionOptionType) => option.label.id === selectedOption?.value
    )

    const updatedDimensions = dimensions.map((d) =>
      d.dimension.id === dimension.dimension.id
        ? {
            dimensionId: d.dimension.id,
            labelId: selectedLabel?.label.id ?? null,
          }
        : {
            dimensionId: d.dimension.id,
            labelId: d.selectedLabel?.id ?? null,
          }
    )

    setDimensions(reservationId, updatedDimensions).then(() => {
      setProcessing(false)
      setHover(false)
      setEditMode(false)
    })
  }

  const validLabels = dimension.validatedOptions.filter(
    (l: DimensionOptionType) => l.validationCategory === 'VALID'
  )
  const invalidLabels = dimension.validatedOptions.filter(
    (l: DimensionOptionType) => l.validationCategory === 'INVALID'
  )

  const validOptions = validLabels.map((l: DimensionOptionType) => ({
    label: l.label.name,
    searchValue: l.label.name,
    value: l.label.id,
  }))

  const invalidOptions = invalidLabels.map((l: DimensionOptionType) => ({
    label: l.label.name,
    searchValue: l.label.name,
    value: l.label.id,
  }))

  const groupedOptions = [
    {
      label: <T>SalesDetails:dimensions.status.valid</T>,
      options: validOptions,
    },
    {
      label: <T>SalesDetails:dimensions.status.invalid</T>,
      options: invalidOptions,
    },
  ]

  const isInvalid = !!invalidOptions.find(
    (o: Option) => o.value === dimension.selectedLabel?.id
  )

  const extraStyles = {
    control: (styles: CSSProperties) => ({
      ...styles,
      '&:hover': {
        borderColor: isInvalid ? palette.danger.main : styles.borderColor,
      },
      borderColor: isInvalid ? palette.danger.main : styles.borderColor,
      boxShadow: isInvalid
        ? `0 0 0 1px ${palette.danger.main}`
        : styles.boxShadow,
      cursor: 'pointer',
      height: '30px',
      marginLeft: `-${spacing.gu(1)}rem`,
      minHeight: '30px',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      marginLeft: `-${spacing.gu(1)}rem`,
      width: `calc(100% + ${spacing.gu(1)}rem)`,
      zIndex: 10005,
    }),
    option: (
      styles: CSSProperties,
      { isSelected }: OptionProps<OptionTypeBase, false>
    ) => ({
      ...styles,
      color: isInvalid && !isSelected ? palette.text.light : styles.color,
      cursor: 'pointer',
    }),
  }

  return isEditMode ? (
    <>
      <ThemedSelect
        autoFocus
        extraStyles={extraStyles}
        formatGroupLabel={GroupLabel}
        isClearable
        isCompact
        isLoading={isProcessing}
        isSearchable
        menuIsOpen={isEditMode}
        name="dimension-selector"
        noOptionsMessage={() => (
          <T>SalesDetails:dimensions.error.emptyLabels</T>
        )}
        onBlur={() => {
          setEditMode(false)
          setHover(false)
        }}
        onChange={(option: Option | null | undefined) => onSetLabel(option)}
        options={groupedOptions}
        placeholder={dimension.dimension.name}
        value={
          dimension.selectedLabel && {
            label: dimension.selectedLabel.name,
            value: dimension.selectedLabel.id,
          }
        }
      />
      {isInvalid && (
        <Tooltip
          content={<T>SalesDetails:dimensions.invalidLabel</T>}
          delay={300}
          color="danger"
          placement="left"
          trigger={(triggerProps) => (
            <div {...triggerProps}>
              <FontAwesomeIcon
                color={palette.danger.main}
                icon="triangle-exclamation"
              />
            </div>
          )}
        />
      )}
    </>
  ) : (
    <EditButton
      disabled={readOnly}
      onClick={() => setEditMode(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {dimension.selectedLabel ? (
        dimension.selectedLabel.name
      ) : (
        <Placeholder>{dimension.dimension.name}</Placeholder>
      )}
      {isHover && (
        <FontAwesomeIcon color={palette.text.lighter} icon="pen" size="sm" />
      )}
    </EditButton>
  )
}

////////////

const GroupLabel = (group: { [key: string]: any }) => (
  <GroupLabelWrapper>
    <span>{group.label}</span>
    <GroupBadge>{group.options.length}</GroupBadge>
  </GroupLabelWrapper>
)

const GroupLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem 0;
  `}
`

const GroupBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    color: ${theme.palette.text.light};
    width: ${theme.spacing.gu(2.5)}rem;
    height: ${theme.spacing.gu(2.5)}rem;
    border-radius: ${theme.spacing.gu(1.25)}rem;
  `}
`
