import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { T } from '@/modules/Language'
import { orderMutations } from '@/modules/Order/mutations'
import {
  useAddPurchaseFromCatalogProductMutation,
  useAddPurchaseFromProductMutation,
} from '@/modules/Products/mutations'
import { salesHooks } from '@/modules/Sales/hooks'

import {
  AdvanceType,
  InvoicePaymentType,
  OrderPhaseType,
  OrderUpdateInvoiceInput,
  PaymentCashAddInput,
  PaymentCreditCardAddInput,
  PaymentGiftCardAddInput,
  PaymentOtherInput,
  PaymentReferenceAddInput,
  PaymentUpdateInput,
  PaymentVoucherAddInput,
  PurchaseProductAddFromCatalogProductInput as AddPurchaseFromPCatalogroductInput,
  PurchaseProductAddFromProductTitleInput as AddPurchaseFromProductInput,
  RefundAction,
} from '~generated-types'

import { Invoice as InvoiceFull, InvoiceInfo, Payment } from '../types'

type Invoice = InvoiceFull | InvoiceInfo

export default function InvoiceService() {
  const { confirm } = useDialogService()
  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  // Invoice mutations
  const [acceptInvoiceMutation] = orderMutations.useAcceptInvoiceMutation()
  const [createAdvanceMutation] = orderMutations.useCreateAdvanceMutation()
  const [createInvoiceMutation] = orderMutations.useCreateInvoiceMutation()
  const [createRefundMutation] = orderMutations.useCreateRefundMutation()
  const [removeInvoiceMutation] = orderMutations.useRemoveInvoiceMutation()
  const [setPaymentTypeMutation] = orderMutations.useSetPaymentTypeMutation()
  const [updateInvoiceMutation] = orderMutations.useUpdateInvoiceMutation()

  // Payment mutations
  const [addCashPaymentMutation] = orderMutations.useAddCashPaymentMutation()
  const [addCreditCardPaymentMutation] =
    orderMutations.useAddCreditCardPaymentMutation()
  const [addGiftCardPaymentMutation] =
    orderMutations.useAddGiftCardPaymentMutation()
  const [addOtherPaymentMutation] = orderMutations.useAddOtherPaymentMutation()
  const [addReferencePaymentMutation] =
    orderMutations.useAddReferencePaymentMutation()
  const [addVoucherPaymentMutation] =
    orderMutations.useAddVoucherPaymentMutation()
  const [cancelExternalPaymentMutation] =
    orderMutations.useCancelExternalPaymentMutation()
  const [refreshPaymentMutation] = orderMutations.useRefreshPaymentMutation()
  const [removeManualPaymentMutation] =
    orderMutations.useRemoveManualPaymentMutation()
  const [updateManualPaymentMutation] =
    orderMutations.useUpdateManualPaymentMutation()

  // Purchase mutations
  const [addPurchaseFromCatalogProductMutation] =
    useAddPurchaseFromCatalogProductMutation()
  const [addPurchaseFromProductMutation] = useAddPurchaseFromProductMutation()

  const updateInvoices = (
    orderId: string,
    getInvoices: (invoices: Invoice[]) => Invoice[]
  ) =>
    setOrdersById((ordersById) => {
      const order = ordersById[orderId]
      const invoices = getInvoices(order.invoices)

      return { ...ordersById, [orderId]: { ...order, invoices } }
    })

  const updatePayments = (
    orderId: string,
    invoiceId: string,
    getPayments: (payments: Payment[]) => Payment[]
  ) =>
    setOrdersById((ordersById) => {
      const order = ordersById[orderId]

      const invoices = order.invoices.map((i) => {
        const isInvoiceFull = (i as InvoiceFull).payments !== undefined

        if (isInvoiceFull && i.id === invoiceId) {
          const invoice = i as InvoiceFull

          return { ...invoice, payments: getPayments(invoice.payments) }
        }

        return i
      })

      return { ...ordersById, [orderId]: { ...order, invoices } }
    })

  const acceptInvoice = (invoiceId: string, orderId: string) =>
    acceptInvoiceMutation({ variables: { input: { invoiceId } } })
      .then(({ data }) => {
        if (data) {
          updateInvoices(orderId, (invoices) =>
            invoices.map((i) =>
              i.id === invoiceId ? data.orderInvoiceAccept.invoice : i
            )
          )
        }
      })
      .catch(() => undefined)

  const addCashPayment = ({
    invoiceId,
    orderId,
    ...props
  }: PaymentCashAddInput & { orderId: string }) =>
    addCashPaymentMutation({ variables: { input: { invoiceId, ...props } } })
      .then(({ data }) => {
        if (data) {
          updatePayments(orderId, invoiceId, (payments) =>
            !payments.find((p) => p.id === data.paymentCashAdd.payment.id)
              ? [...payments, data.paymentCashAdd.payment]
              : payments
          )
        }

        return data?.paymentCashAdd.payment ?? null
      })
      .catch(() => undefined)

  const addCreditCardPayment = ({
    invoiceId,
    orderId,
    ...props
  }: PaymentCreditCardAddInput & { orderId: string }) =>
    addCreditCardPaymentMutation({
      variables: { input: { invoiceId, ...props } },
    })
      .then(({ data }) => {
        if (data) {
          updatePayments(orderId, invoiceId, (payments) =>
            !payments.find((p) => p.id === data.paymentCreditCardAdd.payment.id)
              ? [...payments, data.paymentCreditCardAdd.payment]
              : payments
          )
        }

        return data?.paymentCreditCardAdd.payment ?? null
      })
      .catch(() => undefined)

  const addGiftCardPayment = ({
    invoiceId,
    orderId,
    ...props
  }: PaymentGiftCardAddInput & { orderId: string }) =>
    addGiftCardPaymentMutation({
      variables: { input: { invoiceId, ...props } },
    })
      .then(({ data }) => {
        if (data) {
          updatePayments(orderId, invoiceId, (payments) =>
            !payments.find((p) => p.id === data.paymentGiftCardAdd.payment.id)
              ? [...payments, data.paymentGiftCardAdd.payment]
              : payments
          )
        }

        return data?.paymentGiftCardAdd.payment ?? null
      })
      .catch(() => undefined)

  const addOtherPayment = ({
    invoiceId,
    orderId,
    ...props
  }: PaymentOtherInput & { orderId: string }) =>
    addOtherPaymentMutation({ variables: { input: { invoiceId, ...props } } })
      .then(({ data }) => {
        if (data) {
          updatePayments(orderId, invoiceId, (payments) =>
            !payments.find((p) => p.id === data.paymentOtherAdd.payment.id)
              ? [...payments, data.paymentOtherAdd.payment]
              : payments
          )
        }

        return data?.paymentOtherAdd.payment ?? null
      })
      .catch(() => undefined)

  const addPurchaseFromCatalogProduct = (
    input: AddPurchaseFromPCatalogroductInput
  ) =>
    addPurchaseFromCatalogProductMutation({ variables: { input } }).catch(
      () => undefined
    )

  const addPurchaseFromProduct = (input: AddPurchaseFromProductInput) =>
    addPurchaseFromProductMutation({ variables: { input } }).catch(
      () => undefined
    )

  const addReferencePayment = ({
    invoiceId,
    orderId,
    ...props
  }: PaymentReferenceAddInput & { orderId: string }) =>
    addReferencePaymentMutation({
      variables: { input: { invoiceId, ...props } },
    })
      .then(({ data }) => {
        if (data) {
          updatePayments(orderId, invoiceId, (payments) =>
            !payments.find((p) => p.id === data.paymentReferenceAdd.payment.id)
              ? [...payments, data.paymentReferenceAdd.payment]
              : payments
          )
        }

        return data?.paymentReferenceAdd.payment ?? null
      })
      .catch(() => undefined)

  const addVoucherPayment = ({
    invoiceId,
    orderId,
    ...props
  }: PaymentVoucherAddInput & { orderId: string }) =>
    addVoucherPaymentMutation({ variables: { input: { invoiceId, ...props } } })
      .then(({ data }) => {
        if (data) {
          updatePayments(orderId, invoiceId, (payments) =>
            !payments.find((p) => p.id === data.paymentVoucherAdd.payment.id)
              ? [...payments, data.paymentVoucherAdd.payment]
              : payments
          )
        }

        return data?.paymentVoucherAdd.payment ?? null
      })
      .catch(() => undefined)

  const cancelExternalPayment = (
    invoiceId: string,
    orderId: string,
    paymentId: string
  ) =>
    confirm({
      cancelLabel: <T>common:action.close</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.cancel</T>
        </DangerColor>
      ),
      description: <T>Orders:Confirmation.CANCEL_PAYMENT.description</T>,
      title: <T>Orders:Confirmation.CANCEL_PAYMENT.title</T>,
    })
      .then(() =>
        cancelExternalPaymentMutation({ variables: { id: paymentId } })
          .then(({ data }) => {
            if (data?.externalPaymentCancel.cancelInitiated) {
              updatePayments(orderId, invoiceId, (payments) =>
                payments.filter((p) => p.id !== paymentId)
              )
            }

            return data
          })
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const createAdvance = (orderId: string, advanceType: AdvanceType) =>
    createAdvanceMutation({ variables: { input: { advanceType, orderId } } })
      .then(({ data }) => {
        if (data) {
          const invoice = data.orderCreateAdvance.invoice

          updateInvoices(orderId, (invoices) => {
            const isAlreadyCreated = !!invoices.find(
              ({ id }) => id === invoice.id
            )

            return isAlreadyCreated
              ? invoices.map((i) => (i.id === invoice.id ? invoice : i))
              : [...invoices, invoice]
          })

          return invoice
        }
      })
      .catch(() => undefined)

  const createInvoice = (orderId: string, paymentType: InvoicePaymentType) =>
    createInvoiceMutation({
      variables: { input: { orderId, paymentType } },
    })
      .then(({ data }) => {
        if (data) {
          const invoice = data.orderCreateInvoice.invoice

          updateInvoices(orderId, (invoices) => {
            const isAlreadyCreated = !!invoices.find(
              ({ id }) => id === invoice.id
            )

            return isAlreadyCreated
              ? invoices.map((i) => (i.id === invoice.id ? invoice : i))
              : [...invoices, invoice]
          })

          return invoice
        }
      })
      .catch(() => undefined)

  const createRefund = (
    invoiceId: string,
    orderId: string,
    refundAction: RefundAction
  ) =>
    createRefundMutation({
      variables: { input: { invoiceId, refundAction } },
    })
      .then(({ data }) => {
        if (data) {
          const invoice = data.orderCreateRefund.invoice

          updateInvoices(orderId, (invoices) => {
            const isAlreadyCreated = !!invoices.find(
              ({ id }) => id === invoice.id
            )

            return isAlreadyCreated
              ? invoices.map((i) => (i.id === invoice.id ? invoice : i))
              : [...invoices, invoice]
          })

          return invoice
        }
      })
      .catch(() => undefined)

  const removeInvoice = (
    invoiceId: string,
    orderId: string,
    type: OrderPhaseType
  ) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>{`Orders:Confirmation.REMOVE_${type}.description`}</T>,
      title: <T>{`Orders:Confirmation.REMOVE_${type}.title`}</T>,
    })
      .then(() =>
        removeInvoiceMutation({ variables: { input: { invoiceId } } })
          .then(({ data }) => {
            if (data) {
              updateInvoices(orderId, (invoices) =>
                invoices.filter(({ id }) => id !== invoiceId)
              )
            }

            return data
          })
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const refreshPayment = (
    invoiceId: string,
    orderId: string,
    paymentId: string
  ) =>
    refreshPaymentMutation({ variables: { id: paymentId } })
      .then(({ data }) => {
        if (data && data.paymentRefresh) {
          updatePayments(orderId, invoiceId, (payments) =>
            payments.map((p) =>
              p.id === paymentId ? (data.paymentRefresh as Payment) : p
            )
          )
        }

        return data?.paymentRefresh ?? null
      })
      .catch(() => undefined)

  const removeManualPayment = (
    invoiceId: string,
    orderId: string,
    paymentId: string
  ) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>Orders:Confirmation.REMOVE_PAYMENT.description</T>,
      title: <T>Orders:Confirmation.REMOVE_PAYMENT.title</T>,
    })
      .then(() =>
        removeManualPaymentMutation({ variables: { id: paymentId } })
          .then(({ data }) => {
            if (data?.manualPaymentDelete.deleted) {
              updatePayments(orderId, invoiceId, (payments) =>
                payments.filter((p) => p.id !== paymentId)
              )
            }

            return data
          })
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const setPaymentType = (
    invoiceId: string,
    orderId: string,
    paymentType: InvoicePaymentType
  ) =>
    setPaymentTypeMutation({
      variables: { input: { invoiceId, paymentType } },
    })
      .then(({ data }) => {
        if (data) {
          updateInvoices(orderId, (invoices) =>
            invoices.map((i) =>
              i.id === invoiceId ? data.orderInvoiceSetPaymentType.invoice : i
            )
          )
        }
      })
      .catch(() => undefined)

  const updateInvoice = (input: OrderUpdateInvoiceInput, orderId: string) =>
    updateInvoiceMutation({ variables: { input } })
      .then(({ data }) => {
        if (data) {
          updateInvoices(orderId, (invoices) =>
            invoices.map((i) =>
              i.id === input.id ? data.orderUpdateInvoice.invoice : i
            )
          )
        }
      })
      .catch(() => undefined)

  const updateManualPayment = ({
    invoiceId,
    orderId,
    ...props
  }: PaymentUpdateInput & { invoiceId: string; orderId: string }) =>
    updateManualPaymentMutation({ variables: { input: { ...props } } })
      .then(({ data }) => {
        if (data) {
          updatePayments(orderId, invoiceId, (payments) =>
            payments.map((p) =>
              p.id === props.id ? data.manualPaymentUpdate.payment : p
            )
          )
        }
      })
      .catch(() => undefined)

  return {
    acceptInvoice,
    addCashPayment,
    addCreditCardPayment,
    addGiftCardPayment,
    addOtherPayment,
    addPurchaseFromCatalogProduct,
    addPurchaseFromProduct,
    addReferencePayment,
    addVoucherPayment,
    cancelExternalPayment,
    createAdvance,
    createInvoice,
    createRefund,
    refreshPayment,
    removeInvoice,
    removeManualPayment,
    setPaymentType,
    updateInvoice,
    updateManualPayment,
  }
}
