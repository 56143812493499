import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import { ListingRoomFeatureFragment } from '@/modules/Listing/fragments'
import { ACTIVE_STATES } from '@/modules/Sales/types'

import type {
  AccommodationGuestsListingQuery as QueryData,
  AccommodationGuestsListingQueryVariables as QueryVariables,
  AccommodationGuestsListingRoomReservationFragment as RoomReservation,
} from '~generated-types'

type ParticipantRoom = RoomReservation['participantRooms'][0]
type TargetRoom = RoomReservation['request']['room']

export type GuestDetails = ParticipantRoom
export type Room = TargetRoom

const QUERY = gql`
  ${ListingRoomFeatureFragment}

  fragment AccommodationGuestsListingRoomReservation on RoomReservation {
    id
    request {
      room {
        beds
        building {
          id
          name
        }
        extraBeds
        features {
          ...ListingRoomFeature
        }
        floor
        id
        number
        roomType {
          accommodationLevel {
            name
            shortName
          }
          name
        }
      }
    }
    participantRooms {
      id
      participant {
        accommodationRequest
        additionalInfo
        age
        ageCategory {
          key
          name
          shortName
        }
        birthday {
          date
          month
          year
        }
        firstName
        gender
        id
        lastName
        nationality
        sales {
          customer {
            customer {
              customerNumber
              id

              ... on CustomerOrganization {
                organization {
                  businessId
                  name
                }
              }

              ... on CustomerPerson {
                person {
                  firstName
                  lastName
                }
              }
            }
          }
          id
          lifecycle {
            state {
              systemState
            }
          }
          name
          orderNumber
          type
          facet {
            abbreviation
            color
            id
            name
          }
        }
      }
      request {
        checkIn {
          date
          type
        }
        checkOut {
          date
          type
        }
        info
      }
    }
  }

  query AccommodationGuestsListing($input: ReservationSearchInput!) {
    accommodationRoomReservations(input: $input) {
      arriving {
        ...AccommodationGuestsListingRoomReservation
      }
      present {
        ...AccommodationGuestsListingRoomReservation
      }
    }
  }
`

interface Props {
  date: Moment
}

export interface AccommodationGuest {
  guest: ParticipantRoom
  room: Room
}

interface Hook {
  error: boolean
  guests: AccommodationGuest[]
  loading: boolean
}

const useAccommodationGuests = ({ date }: Props): Hook => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        date: date.format('YYYY-MM-DD'),
      },
    },
  })

  /**
   * In this context, we are only interested in the people who are arriving or
   * present i.e. who is staying in for the upcoming night from the reference
   * date.
   */

  const guests: AccommodationGuest[] = []

  const mapParticipantRooms = ({
    participantRooms,
    request,
  }: RoomReservation) => {
    participantRooms.forEach((x) => {
      const systemState = x.participant.sales.lifecycle.state.systemState

      if (ACTIVE_STATES.includes(systemState)) {
        guests.push({
          guest: x,
          room: request.room,
        })
      }
    })
  }

  data?.accommodationRoomReservations.arriving.forEach(mapParticipantRooms)
  data?.accommodationRoomReservations.present.forEach(mapParticipantRooms)

  return {
    error: !!error,
    guests,
    loading,
  }
}

export default useAccommodationGuests
