import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { useWillbaConfigContext } from '@/config/WillbaConfigContext'
import { T } from '@/modules/Language'
import { Theme, useTheme } from '@/theme'

import { IconWrapper, TooltipLine } from './common'

type Props = {
  checkIn?: string | null
  disabled: boolean
  handleSetCheckIn: (providedTime?: string | null) => Promise<any>
  isAvailable?: boolean
  isValid: boolean
}

export const ParticipantCheckIn = ({
  checkIn,
  disabled,
  handleSetCheckIn,
  isAvailable = true,
  isValid,
}: Props) => {
  const theme = useTheme()
  const { timezone } = useWillbaConfigContext()

  const [isHover, setHover] = useState<boolean>(false)

  const config = { theme, timezone }
  const readOnly = !isAvailable || disabled

  const checkInColor = isAvailable
    ? theme.palette.gold
    : theme.palette.smoke.dark

  const undoCheckInColor = isHover
    ? isValid
      ? theme.palette.danger.main
      : theme.palette.smoke.dark
    : theme.palette.success.main

  const handleCheckIn = () =>
    !readOnly &&
    handleSetCheckIn(moment().tz(timezone).format('YYYY-MM-DDTHH:mm:ss')).then(
      () => setHover(false)
    )

  const handleUndoCheckIn = () =>
    !readOnly && isValid && handleSetCheckIn(null).then(() => setHover(false))

  const renderUndoCheckInIcon = () => (
    <IconWrapper
      disabled={readOnly || !isValid}
      onClick={handleUndoCheckIn}
      onMouseEnter={() => !readOnly && setHover(true)}
      onMouseLeave={() => !readOnly && setHover(false)}
      borderColor={undoCheckInColor}
    >
      <FontAwesomeIcon
        icon={isHover ? 'xmark' : 'check'}
        color={undoCheckInColor}
      />
    </IconWrapper>
  )

  const renderCheckInIcon = () => (
    <IconWrapper
      disabled={readOnly}
      onClick={handleCheckIn}
      onMouseEnter={() => !readOnly && setHover(true)}
      onMouseLeave={() => !readOnly && setHover(false)}
      borderColor={checkInColor}
    >
      <FontAwesomeIcon
        icon={isHover ? 'check' : 'right-to-bracket'}
        color={checkInColor}
      />
    </IconWrapper>
  )

  return (
    <FlexRow alignItems="center">
      {checkIn ? (
        <Tooltip
          content={getCheckInLabel({
            checkIn,
            isValid: isValid || disabled,
            ...config,
          })}
          delay={300}
          maxWidth={180}
          placement="top"
          trigger={(triggerProps) => (
            <div {...triggerProps} style={{ margin: 'auto' }}>
              {renderUndoCheckInIcon()}
            </div>
          )}
        />
      ) : disabled ? (
        renderCheckInIcon()
      ) : (
        <Tooltip
          content={getCheckInLabel({ isAvailable, ...config })}
          delay={300}
          maxWidth={180}
          placement="top"
          trigger={(triggerProps) => (
            <div {...triggerProps} style={{ margin: 'auto' }}>
              {renderCheckInIcon()}
            </div>
          )}
        />
      )}
    </FlexRow>
  )
}

///////

type CheckInLabelProps = {
  checkIn?: string
  isAvailable?: boolean
  isValid?: boolean
  timezone: string
  theme: Theme
}

const getCheckInLabel = ({
  checkIn,
  isAvailable = true,
  isValid = true,
  timezone,
  theme,
}: CheckInLabelProps) => {
  if (!isAvailable) {
    return <T>ParticipantsList:ParticipantFormFields.noRoomWarning</T>
  }

  return (
    <FlexColumn noPadding alignItems="flex-start">
      <span style={{ marginBottom: theme.spacing.gutterSmall }}>
        <T>{`ParticipantsList:ParticipantFormFields.${
          checkIn ? 'checkedInAt' : 'setCheckInTo'
        }`}</T>
      </span>
      <span>
        {checkIn
          ? moment(checkIn).format('DD.MM.YYYY HH:mm')
          : moment().tz(timezone).format('DD.MM.YYYY HH:mm')}
      </span>

      {!isValid && (
        <>
          <TooltipLine />
          <span style={{ textAlign: 'start' }}>
            <T>ParticipantsList:ParticipantFormFields.checkInUndoValidation</T>
          </span>
        </>
      )}
    </FlexColumn>
  )
}
