import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { FontWeight } from '@/components/Typography'
import { Beds } from '@/modules/Accommodation/SalesReservationList/components/ReservationRow/components'
import { useGlobalIconsContext, useTheme } from '@/theme'

import { RoomCleaningStatus, SaleAccommodationQuery } from '~generated-types'

type SuitableRoom = NonNullable<SaleAccommodationQuery['suitableRooms'][0]>
type RoomReservation = SuitableRoom['roomReservation']
type Request = RoomReservation['request']
type ParticipantRoom = RoomReservation['participantRooms'][0]

type UpdateRoomBedsInput = {
  beds: number
  extraBeds: number
  reservationId: string
}

type Props = {
  participantRooms: ParticipantRoom[]
  request: Request
  reservationId: string
  updateRoomBeds: (input: UpdateRoomBedsInput) => Promise<any>
}

export const ParticipantRoomLabel = ({
  participantRooms,
  request,
  reservationId,
  updateRoomBeds,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { checkIconValidity } = useGlobalIconsContext()

  const { checkIn, checkOut, room, beds, extraBeds, features } = request

  const participantCount = new Set(
    participantRooms.map(({ participant }) => participant.id)
  ).size

  const showCleaningStatus =
    moment().isSame(checkIn.date, 'day') ||
    moment().isSame(checkOut.date, 'day')

  const showOccupiedStatus =
    moment().isAfter(checkIn.date, 'day') &&
    moment().isBefore(checkOut.date, 'day')

  return (
    <FlexRow alignItems="center" justifyContent="flex-start">
      <FlexRow style={{ width: `${spacing.gu(14)}rem` }}>
        <RoomNumber semiBold>
          <Tooltip
            content={room.number}
            delay={300}
            placement="top"
            trigger={(triggerProps) => (
              <span {...triggerProps}>{`#${room.number}`}</span>
            )}
          />
        </RoomNumber>

        {showCleaningStatus ? (
          <FontAwesomeIcon
            color={
              room.status.cleaningStatus === RoomCleaningStatus.Clean
                ? palette.success.main
                : palette.danger.main
            }
            fixedWidth
            icon="broom"
            style={{ width: spacing.gutter }}
          />
        ) : showOccupiedStatus ? (
          <FontAwesomeIcon
            color={palette.primary.main}
            fixedWidth
            icon="user"
            size="sm"
            style={{ width: spacing.gutter }}
          />
        ) : (
          <span style={{ width: spacing.gutter }} />
        )}
      </FlexRow>

      <FlexRow
        alignItems="center"
        justifyContent="center"
        style={{ width: `${spacing.gu(24)}rem` }}
      >
        <DateIconWrapper>
          {checkIn.type === CheckInTypes.EARLY && (
            <FontAwesomeIcon
              color={palette.text.light}
              fixedWidth
              icon="sun"
              size="sm"
            />
          )}
        </DateIconWrapper>

        <Date>{formatDate(checkIn.date, checkOut.date)}</Date>

        <DateIconWrapper>
          {checkOut.type === CheckOutTypes.LATE && (
            <FontAwesomeIcon
              color={palette.text.light}
              fixedWidth
              icon="moon"
              size="xs"
            />
          )}
        </DateIconWrapper>
      </FlexRow>

      <FlexRow
        justifyContent="center"
        style={{ height: '32px', width: `${spacing.gu(28)}rem` }}
      >
        <Beds
          beds={beds}
          extraBeds={extraBeds}
          updateRoomBeds={updateRoomBeds}
          reservationId={reservationId}
          totalBeds={room.beds}
          totalExtraBeds={room.extraBeds}
        />
      </FlexRow>

      <FlexRow style={{ width: `${spacing.gu(8)}rem` }}>
        <FontAwesomeIcon
          color={
            participantCount === request.beds + request.extraBeds
              ? palette.success.main
              : palette.gold
          }
          fixedWidth
          icon="user-group"
          style={{ margin: `0 ${spacing.gu(1)}rem`, width: spacing.gutter }}
        />
        <FontWeight style={{ marginRight: spacing.gutterSmall }}>
          {participantCount}
        </FontWeight>
      </FlexRow>

      <FlexRow style={{ width: `${spacing.gu(18)}rem` }}>
        <FontWeight semiBold style={{ marginRight: `${spacing.gu(1)}rem` }}>
          {room.roomType.accommodationLevel.shortName}
        </FontWeight>

        <FontWeight semiBold style={{ marginRight: `${spacing.gu(1)}rem` }}>
          {room.roomType.name}
        </FontWeight>
      </FlexRow>

      <FlexRow>
        {features.map(({ icon, id, name, shortName }) => (
          <Tooltip
            key={id}
            content={name}
            delay={300}
            placement="top"
            trigger={(triggerProps) => (
              <div
                {...triggerProps}
                style={{ marginRight: spacing.gutterSmall }}
              >
                {checkIconValidity(icon) ? (
                  <FontAwesomeIcon
                    color={palette.coal.main}
                    icon={icon as IconProp}
                  />
                ) : (
                  shortName
                )}
              </div>
            )}
          />
        ))}
      </FlexRow>
    </FlexRow>
  )
}

////////

const formatDate = (checkIn: string, checkOut: string) => {
  const isSameYear = moment(checkIn).isSame(moment(checkOut), 'year')

  return `${moment(checkIn).format(
    isSameYear ? 'DD.MM' : 'DD.MM.YY'
  )} – ${moment(checkOut).format('DD.MM.YY')}`
}

const Date = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const DateIconWrapper = styled.div`
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(3)}rem;
  `}
`

const RoomNumber = styled(FontWeight)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    max-width: ${theme.spacing.gu(11)}rem;
  `}
`
