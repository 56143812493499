import { useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components/macro'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { DropdownButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T, useLanguageContext } from '@/modules/Language'
import {
  useCancelSaleMutation,
  useDeleteSaleMutation,
} from '@/modules/Sales/mutations'
import {
  salesQueries,
  SalesStateOptionsPayload,
  SalesStateOptionsVariables,
} from '@/modules/Sales/queries'

import { SalesSystemState } from '~generated-types'

import { useSalesDetailsContext } from '../../../../SalesDetailsState'
import { CopySaleModal } from '../CopySaleModal'
import { PrintSaleModal } from '../PrintSaleModal'
import { CancelDescription } from './CancelDescription'

export const Actions = () => {
  const history = useHistory()
  const { confirm } = useDialogService()
  const { language } = useLanguageContext()

  const {
    data: {
      id: salesId,
      lifecycle: { specifier },
    },
  } = useSalesDetailsContext()

  const selectedCancelReason = useRef<null | string>(null)

  const [isDropdowOpen, setDropdownOpen] = useState<boolean>(false)
  const [isCopySaleModalOpen, setCopySaleModalOpen] = useState<boolean>(false)
  const [isPrintSaleModalOpen, setPrintSaleModalOpen] = useState<boolean>(false)

  const [cancelSale] = useCancelSaleMutation()
  const [deleteSale] = useDeleteSaleMutation()

  const { data: salesStates } = useQuery<
    SalesStateOptionsPayload,
    SalesStateOptionsVariables
  >(salesQueries.SALES_STATE_OPTIONS, {
    fetchPolicy: 'network-only',
    skip: !isDropdowOpen,
    variables: { id: salesId },
  })

  const cancelAction = salesStates?.sales.lifecycle.stateOptions.find(
    ({ state }) => state.systemState === SalesSystemState.Cancelled
  )

  const cancelProcessAction = salesStates?.sales.lifecycle.stateOptions.find(
    ({ state }) => state.systemState === SalesSystemState.CancelProcess
  )

  const deleteAction = salesStates?.sales.lifecycle.stateOptions.find(
    ({ state }) => state.systemState === SalesSystemState.Deleted
  )

  const handleCancelSale = () =>
    cancelSale({
      variables: {
        input: { salesId, specifier: selectedCancelReason.current },
      },
    }).then(() => undefined)

  const handleDeleteSale = () =>
    deleteSale({ variables: { input: { salesId } } }).then((res) => {
      res.data?.salesDelete?.deleted && history.replace('/search/sales')
    })

  const isCancelValid = !!cancelAction?.valid || !!cancelProcessAction?.valid
  const isDeleteValid = !!deleteAction?.valid

  const cancelOption =
    cancelAction || cancelProcessAction
      ? [
          {
            disabled: !isCancelValid,
            label: (
              <DangerColor style={{ opacity: isCancelValid ? 1 : 0.7 }}>
                <FlexRow>
                  <OptionIcon icon="xmark" />
                  <T>SalesDetails:Lifecycle.action.CANCEL</T>
                </FlexRow>
              </DangerColor>
            ),
            onClick: () =>
              confirm({
                cancelLabel: <T>common:action.cancel</T>,
                confirmLabel: (
                  <DangerColor>
                    <T>common:action.continue</T>
                  </DangerColor>
                ),
                description: (
                  <CancelDescription
                    language={language}
                    setSeletedReason={(reason: string) =>
                      (selectedCancelReason.current = reason)
                    }
                    specifier={specifier}
                  />
                ),
                title: <T>SalesDetails:Lifecycle.action.full.CANCEL</T>,
              })
                .then(handleCancelSale)
                .catch(() => null)
                .finally(() => (selectedCancelReason.current = null)),
          },
        ]
      : []

  const deleteOption = deleteAction
    ? [
        {
          disabled: !isDeleteValid,
          label: (
            <DangerColor style={{ opacity: isDeleteValid ? 1 : 0.7 }}>
              <FlexRow>
                <OptionIcon icon="trash" />
                <T>SalesDetails:Lifecycle.action.DELETE</T>
              </FlexRow>
            </DangerColor>
          ),
          onClick: () =>
            confirm({
              cancelLabel: <T>common:action.cancel</T>,
              confirmLabel: (
                <DangerColor>
                  <T>common:action.continue</T>
                </DangerColor>
              ),
              description: (
                <FlexColumn noPadding>
                  <span>
                    <T>SalesDetails:Lifecycle.common.areYouSure</T>
                  </span>
                  <span>
                    <T>SalesDetails:Lifecycle.description.DELETE</T>
                  </span>
                </FlexColumn>
              ),
              title: <T>SalesDetails:Lifecycle.action.full.DELETE</T>,
            })
              .then(handleDeleteSale)
              .catch(() => null),
        },
      ]
    : []

  const options = [
    {
      label: (
        <FlexRow>
          <OptionIcon icon={['far', 'clone']} />
          <T>SalesDetails:CopySale.copy</T>
        </FlexRow>
      ),
      onClick: () => setCopySaleModalOpen(true),
    },
    {
      label: (
        <FlexRow>
          <OptionIcon icon="print" />
          <T>SalesDetails:PrintSale.print</T>
        </FlexRow>
      ),
      onClick: () => setPrintSaleModalOpen(true),
    },
    ...cancelOption,
    ...deleteOption,
  ]

  return (
    <>
      <DropdownButton
        customButtonStyle={{ height: '100%' }}
        options={options}
        dropdownPlacement="bottom-start"
        isDropdowOpen={isDropdowOpen && !!salesStates}
        onClose={() => setDropdownOpen(false)}
        renderCustomButton={() => (
          <IconButton onClick={() => setDropdownOpen(true)}>
            <FontAwesomeIcon icon="ellipsis-vertical" size="lg" />
          </IconButton>
        )}
        setDropdownOpen={setDropdownOpen}
      />

      <ModalContainer
        isOpen={isPrintSaleModalOpen}
        modal={<PrintSaleModal onClose={() => setPrintSaleModalOpen(false)} />}
        onClose={() => setPrintSaleModalOpen(false)}
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />

      <ModalContainer
        isOpen={isCopySaleModalOpen}
        modal={<CopySaleModal onClose={() => setCopySaleModalOpen(false)} />}
        onClose={() => setCopySaleModalOpen(false)}
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

////////////

const OptionIcon = styled(FontAwesomeIcon)`
  && {
    width: 20px;
    margin-right: 8px;
  }
`

const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  height: 100%;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    width: ${theme.spacing.gu(8)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`
