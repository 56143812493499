import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { MealQuantity } from '@/modules/Meals'
import { useTheme } from '@/theme'

import { MealRowsByDate } from '../../../../types'
import { Column, Header, Row, ShortSeparator } from '../../../common'
import { PersonRow } from './PersonRow'

type Props = {
  day: MealRowsByDate
}

export const PersonSection = ({ day: { meals } }: Props) => {
  const { palette, spacing } = useTheme()

  return (
    <Column>
      <Row style={{ height: `${spacing.gu(3)}rem` }}>
        {[...meals[0].quantities]
          .sort(compareFn)
          .map(({ ageCategory }, idx) => (
            <Row key={`header-row-${ageCategory?.key ?? ''}-${idx}`}>
              <Header width={`${spacing.gu(6)}rem`}>
                {ageCategory ? (
                  ageCategory.shortName
                ) : (
                  <Tooltip
                    content={<T>SalesDetails:meals.warning.noAgeCategory</T>}
                    delay={300}
                    placement="top"
                    trigger={(triggerProps) => (
                      <span {...triggerProps}>
                        <FontAwesomeIcon
                          color={palette.warning.dark}
                          icon="triangle-exclamation"
                          size="sm"
                        />
                      </span>
                    )}
                  />
                )}
              </Header>
              <ShortSeparator />
            </Row>
          ))}

        <Header width={`${spacing.gu(6)}rem`}>
          <T>SalesDetails:meals.title.total</T>
        </Header>
      </Row>

      {meals.map((meal) => (
        <PersonRow key={meal.rowId} meal={meal} />
      ))}
    </Column>
  )
}

/////

const compareFn = (a: MealQuantity, b: MealQuantity) => {
  if (!a.ageCategory || !b.ageCategory) {
    return 1
  }

  return a.ageCategory.sortOrder - b.ageCategory.sortOrder
}
