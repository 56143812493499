import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option } from '@/components/ThemedSelect'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { PaymentType } from '../types'

type Values = {
  amount: number
  paymentType: PaymentType | null
  pointOfSale: Option | null
}

type Props = {
  handleAddPayment: () => Promise<void>
  isProcessing: boolean
  values: Values
}

export const Actions = ({
  handleAddPayment,
  isProcessing,
  values: { amount, paymentType, pointOfSale },
}: Props) => {
  const { palette, spacing } = useTheme()

  const isValid = !!amount && !!paymentType && !!pointOfSale

  const renderButtonContent = () => (
    <PrimaryColor>
      <FontAwesomeIcon
        icon="plus"
        size="sm"
        style={{ marginRight: `${spacing.gu(1)}rem` }}
      />
      <T>Orders:Payments.actions.add</T>
    </PrimaryColor>
  )

  const renderLoader = () => (
    <ReactLoading
      type="spin"
      color={palette.primary.main}
      height={24}
      width={24}
    />
  )

  const renderValidationLabel = () => (
    <FlexColumn alignItems="flex-start" noPadding>
      <span>{!amount && <T>Orders:Payments.tooltip.amount</T>}</span>
      <span>{!paymentType && <T>Orders:Payments.tooltip.paymentType</T>}</span>
      <span>{!pointOfSale && <T>Orders:Payments.tooltip.pointOfSale</T>}</span>
    </FlexColumn>
  )

  return (
    <Wrapper>
      {isValid ? (
        <Button disabled={isProcessing} noNudge onClick={handleAddPayment}>
          {isProcessing ? renderLoader() : renderButtonContent()}
        </Button>
      ) : (
        <Tooltip
          content={renderValidationLabel()}
          delay={300}
          trigger={(triggerProps) => (
            <span {...triggerProps} style={{ cursor: 'not-allowed', flex: 1 }}>
              <Button disabled>{renderButtonContent()}</Button>
            </span>
          )}
        />
      )}
    </Wrapper>
  )
}

///////

const Button = styled(InnocuousButton)`
  width: 100%;
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter} ${theme.spacing.gu(1)}rem;
  `}
`
