export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AbstractAccommodationDates: [
      'AccommodationDates',
      'ParticipantRoomRequest',
      'RoomRequest',
      'RoomTypeRequest',
    ],
    AccommodationGroupBase: [
      'AccommodationGroup',
      'AccommodationGroupBaseImpl',
    ],
    AccommodationRequest: [
      'ParticipantRoomRequest',
      'RoomRequest',
      'RoomTypeRequest',
    ],
    AccommodationReservationContextEventType: [
      'AccommodationGroupEvent',
      'RoomEvent',
      'RoomTypeEvent',
      'TargetEvent',
    ],
    AccommodationRoomReservationPayload: [
      'AccommodationRoomReservationPayloadOk',
      'RoomReservationCapacityRestricted',
      'RoomReservationOverlappingError',
    ],
    AccommodationUpdateAllPayload: [
      'AccommodationUpdateAllError',
      'AccommodationUpdateAllPayloadOk',
    ],
    ActionRule: ['AmountPriceRule', 'ExcludePriceRule', 'OnlyPriceRule'],
    AuditLogBase: [
      'AuditLog',
      'InvoiceAuditLog',
      'OfferAuditLog',
      'TaskAuditLog',
    ],
    Audited: [
      'CustomerOrganization',
      'CustomerOrganizationContact',
      'CustomerPerson',
      'Invoice',
      'Offer',
      'Order',
      'OrderConfirmation',
      'SalesParticipant',
    ],
    AvailabilityStatus: [
      'RoomAvailabilityStatus',
      'RoomTypeAvailabilityStatus',
    ],
    CatalogProductOld: [
      'ProductTitleAccommodation',
      'ProductTitleBasic',
      'ProductTitleCalendar',
      'ProductTitlePacketMeal',
    ],
    ConditionRule: [
      'AgeCategoryRule',
      'BoundaryRule',
      'DateRule',
      'QuantityRule',
      'WeekdayRule',
    ],
    Connection: [
      'ParticipantConnection',
      'PublicRoomConnection',
      'SalesConnection',
    ],
    Customer: ['CustomerOrganization', 'CustomerPerson'],
    DocumentContextEventType: ['DocumentEvent'],
    DocumentOwner: ['Invoice', 'Offer', 'OrderConfirmation'],
    DocumentsOwner: ['Sales'],
    Event: [
      'AccommodationGroupEvent',
      'CustomerEvent',
      'DocumentEvent',
      'OrderConfirmationEvent',
      'OrderEvent',
      'OrderInvoiceEvent',
      'OrderItemEvent',
      'OrderOfferEvent',
      'ParticipantEvent',
      'ParticipantRoomEvent',
      'PaymentEvent',
      'RoomEvent',
      'RoomTypeEvent',
      'SalesCustomerEvent',
      'SalesDimensionEvent',
      'SalesTokenEvent',
      'ServiceParticipantEvent',
      'TargetEvent',
    ],
    InvoiceInfo: ['Invoice'],
    Lockable: ['Sales'],
    MealLocation: [
      'ResourceEquipment',
      'ResourceHuman',
      'ResourceProperty',
      'ResourcePropertyNested',
      'Restaurant',
      'Room',
    ],
    NavDropdownItem: ['NavExternalView', 'NavInternalView'],
    Node: [
      'AccommodationGroup',
      'AccommodationGroupBaseImpl',
      'AccommodationTarget',
      'CustomerOrganization',
      'CustomerPerson',
      'CustomerReference',
      'Invoice',
      'Offer',
      'Order',
      'OrderConfirmation',
      'OrderPacket',
      'OrderProduct',
      'ParticipantRoom',
      'PublicRoom',
      'ResourceReservation',
      'RoomReservation',
      'RoomTypeReservation',
      'Sales',
      'SalesParticipant',
      'Task',
    ],
    OfferInfo: ['Offer'],
    OrderConfirmationInfo: ['OrderConfirmation'],
    OrderContextEventType: [
      'OrderConfirmationEvent',
      'OrderEvent',
      'OrderInvoiceEvent',
      'OrderItemEvent',
      'OrderOfferEvent',
    ],
    OrderInfo: ['Order'],
    OrderItem: ['OrderPacket', 'OrderProduct'],
    OrderPhase: ['Invoice', 'Offer', 'OrderConfirmation'],
    ParticipantContextEventType: [
      'ParticipantEvent',
      'ServiceParticipantEvent',
    ],
    PaymentContextEventType: ['PaymentEvent'],
    PaymentDetails: [
      'PaymentCashDetails',
      'PaymentCreditCardDetails',
      'PaymentGiftCardDetails',
      'PaymentOtherDetails',
      'PaymentReferenceTransferDetails',
      'PaymentRefundDetails',
      'PaymentReservationFeeDetails',
      'PaymentVoucherDetails',
    ],
    Person: ['CustomerOrganizationContact', 'CustomerPersonData'],
    PostalAddress: ['PostalAddressType'],
    ReservationPayload: [
      'ReservationAvailabilityError',
      'ReservationAvailabilityWarning',
      'ReservationPayloadOk',
    ],
    Resource: [
      'ResourceEquipment',
      'ResourceHuman',
      'ResourceProperty',
      'ResourcePropertyNested',
    ],
    RoomReservationError: [
      'RoomReservationCapacityRestricted',
      'RoomReservationOverlappingError',
    ],
    SalesContextEventType: [
      'SalesCustomerEvent',
      'SalesDimensionEvent',
      'SalesTokenEvent',
    ],
    SalesParticipantBase: ['SalesParticipant'],
    Service: ['ServiceParticipantBed', 'ServicePurchase'],
    ServiceParticipantConnection: ['ServiceParticipantBed', 'ServicePurchase'],
    Sortable: ['AccommodationTarget', 'SalesParticipant'],
    TaskAssignee: ['User', 'UserGroup'],
    Validated: [
      'InvoiceActionValidated',
      'OfferActionValidated',
      'OrderActionValidated',
      'OrderConfirmationActionValidated',
      'PaymentActionValidated',
      'PurchaseProductActionValidated',
      'ValidatedSalesStateOption',
    ],
    Versioned: ['Invoice', 'Offer', 'OrderConfirmation'],
  },
}
export default result
