import { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Manager, Popper, Reference } from 'react-popper'
import styled, { css } from 'styled-components/macro'

export type Color = 'primary' | 'default' | 'danger' | 'warning' | 'success'

type AutoPlacement = 'auto' | 'auto-start' | 'auto-end'
type BasePlacement = 'top' | 'bottom' | 'right' | 'left'
type VariationPlacement =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'

export type Placement = AutoPlacement | BasePlacement | VariationPlacement

type Props = {
  content: ReactNode // The tooltip content
  color?: Color
  delay?: number | [number, number] // Universal delay or [delayIn, delayOut]
  disabled?: boolean
  maxWidth?: number | string
  placement?: Placement
  trigger: (arg0: { [key: string]: any }) => ReactNode // The trigger
}

const defaultDelay = 0

export const Tooltip = ({
  content,
  color,
  delay,
  disabled,
  maxWidth,
  placement,
  trigger,
}: Props) => {
  const hasUnmounted = useRef<boolean>(false)

  const hideDelay = Array.isArray(delay) ? delay[1] : delay || defaultDelay
  const showDelay = Array.isArray(delay) ? delay[0] : delay || defaultDelay

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [hideTimeout, setHideTimeout] = useState<
    NodeJS.Timeout | null | undefined
  >(null)
  const [showTimeout, setShowTimeout] = useState<
    NodeJS.Timeout | null | undefined
  >(null)

  const setVisible = (visible: boolean) =>
    !hasUnmounted.current && setIsVisible(visible)

  const hideTooltip = () => {
    if (showTimeout) {
      clearTimeout(showTimeout)
    }

    if (!hasUnmounted.current) {
      setHideTimeout(setTimeout(() => setVisible(false), hideDelay))
    }
  }

  const showTooltip = () => {
    if (hideTimeout) {
      clearTimeout(hideTimeout)
    }

    if (!hasUnmounted.current) {
      setShowTimeout(setTimeout(() => setVisible(true), showDelay))
    }
  }

  useEffect(
    () => () => {
      hasUnmounted.current = true
    },
    []
  )

  return (
    <Manager>
      <Reference>
        {({ ref }) =>
          trigger({
            onMouseOut: hideTooltip,
            onMouseOver: showTooltip,
            ref,
          })
        }
      </Reference>
      {!disabled &&
        isVisible &&
        createPortal(
          <Popper
            placement={placement || 'top'}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ]}
          >
            {(popperOptions) => (
              <TooltipWrapper
                color={color}
                maxWidth={maxWidth}
                ref={popperOptions.ref}
                style={{ ...popperOptions.style, zIndex: 10010 }}
              >
                {content}
              </TooltipWrapper>
            )}
          </Popper>,
          document.getElementById('popper-root') as any
        )}
    </Manager>
  )
}

////////////

const TooltipWrapper = styled.div<{
  color?: Color
  maxWidth?: string | number
}>`
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.12);
  opacity: 0.9;
  text-align: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem;
    color: ${theme.palette.text.white};
  `}

  ${({ color }) => colorReconciler(color)};
  ${({ maxWidth }) => maxWidthReconciler(maxWidth)};
`

const colorReconciler = (color?: Color) => {
  switch (color) {
    case 'danger':
      return css`
        ${({ theme }) => css`
          background: ${theme.palette.danger.main};
        `}
      `
    case 'warning':
      return css`
        ${({ theme }) => css`
          background: ${theme.palette.warning.main};
          color: ${theme.palette.text.main};
        `}
      `
    case 'success':
      return css`
        ${({ theme }) => css`
          background: ${theme.palette.success.main};
        `}
      `
    case 'primary':
      return css`
        ${({ theme }) => css`
          background: ${theme.palette.primary.main};
        `}
      `
    case 'default':
    default:
      return css`
        ${({ theme }) => css`
          background: ${theme.palette.coal.dark};
        `}
      `
  }
}

const maxWidthReconciler = (maxWidth?: string | number) =>
  typeof maxWidth === 'number'
    ? css`
        max-width: ${maxWidth}px;
      `
    : !!maxWidth
    ? css`
        max-width: ${maxWidth};
      `
    : ''
