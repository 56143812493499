import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { SalesProductManager } from '@/modules/Products'
import { ProductPurchase } from '@/modules/Products/types'
import { salesHooks } from '@/modules/Sales/hooks'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import {
  Feature,
  PurchaseProductAction as PPA,
  QuantityUnit as QU,
} from '~generated-types'

import { Actions } from './Actions'
import { Date } from './Date'
import { TargetInfo } from './TargetInfo'

type Props = {
  isFirst: boolean
  isLast: boolean
  purchase: ProductPurchase
  quantityUnit: QU
}

export const SalesPurchase = ({
  isFirst,
  isLast,
  purchase,
  quantityUnit,
}: Props) => {
  const {
    attributes: { duration, quantity },
    id,
    link,
    order: { orderNumber },
    product: { id: productId, name },
    status,
    targetType,
    totalPrice: { amountVatIncluded: price },
  } = purchase

  const { spacing } = useTheme()
  const {
    data: {
      commissionRate,
      facet: { features },
      id: salesId,
      seller,
      type: salesType,
    },
    saleReadOnly,
  } = salesHooks.useSalesDetailsContext()

  const isCommissionEnabled = !!features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const [hover, setHover] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [subfieldHover, setSubfieldHover] = useState<boolean>(false)

  const onSubfieldHover = (hover: boolean) => {
    setHover(!hover)
    setSubfieldHover(hover)
  }

  const mouseActionsHandler = {
    hover,
    onClick: () => (hover ? setModalOpen(true) : null),
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
    onMouseOver: () => (subfieldHover ? null : setHover(true)),
  }

  const subfieldMouseActionsHandler = {
    onMouseEnter: () => onSubfieldHover(true),
    onMouseLeave: () => onSubfieldHover(false),
  }

  const purchaseReadOnly = !status.validatedActions.find(
    (a) => a.action === PPA.Update
  )?.valid

  return (
    <>
      <Wrapper
        alignItems="center"
        isFirst={isFirst}
        isLast={isLast}
        {...mouseActionsHandler}
      >
        <NameContainer>
          {purchaseReadOnly && !saleReadOnly && (
            <Tooltip
              content={<T>Products:SalesProductList.lockedProduct</T>}
              delay={300}
              placement="top"
              trigger={(triggerProps) => (
                <span {...triggerProps}>
                  <Icon fixedWidth icon="lock" />
                </span>
              )}
            />
          )}

          <NameWrapper
            alignItems="flex-start"
            justifyContent="center"
            noPadding
          >
            <Name>{name}</Name>
          </NameWrapper>
        </NameContainer>

        <Divider />

        <OrderNumber justifyContent="center">#{orderNumber}</OrderNumber>

        <Divider />

        <Quantity justifyContent="flex-end">
          {quantity} <T>enums:quantityUnit.abbrev.PIECE</T>
        </Quantity>

        <Divider />

        <Date duration={duration} quantityUnit={quantityUnit} />

        <Divider />

        <TargetInfo link={link} targetType={targetType} />

        <Spacer />

        <Price>{formatCurrency(price)} €</Price>

        {!saleReadOnly && (
          <>
            <Divider
              style={{ margin: `0 ${spacing.gu(1)}rem 0 ${spacing.gu(2)}rem` }}
            />

            <FlexRow {...subfieldMouseActionsHandler}>
              <Actions
                productId={productId}
                purchase={purchase}
                readOnly={purchaseReadOnly}
              />
            </FlexRow>
          </>
        )}
      </Wrapper>

      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <SalesProductManager
            commission={commission}
            context="SALES"
            onClose={() => setModalOpen(false)}
            productId={productId}
            readOnly={saleReadOnly}
            salesId={salesId}
            salesType={salesType}
            sellerId={seller?.id}
            targetPurchaseId={id}
          />
        }
        onClose={() => setModalOpen(false)}
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

///////

const Divider = styled.div`
  width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
    height: ${theme.spacing.gu(4)}rem;
    margin: 0 ${theme.spacing.gu(2)}rem;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(0.5)}rem 0 ${theme.spacing.gu(1)}rem;
    color: ${theme.palette.primary.dark};
  `}
`

const Name = styled.span`
  font-weight: 500;
`

const NameContainer = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
    max-width: ${theme.spacing.gu(36)}rem;
    min-width: ${theme.spacing.gu(36)}rem;
  `}
`

const NameWrapper = styled(FlexColumn)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`

const OrderNumber = styled(FlexRow)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(8)}rem;
  `}
`

const Price = styled.span`
  font-weight: 500;
  text-align: right;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(16)}rem;
  `}
`

const Quantity = styled(FlexRow)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(8)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const Wrapper = styled(FlexRow)<{
  hover: boolean
  isFirst: boolean
  isLast: boolean
}>`
  cursor: pointer;
  transition: 0.2s;
  margin-top: -1px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ isLast, isFirst }) => css`
    border-bottom-left-radius: ${isLast ? '6px' : '0px'};
    border-bottom-right-radius: ${isLast ? '6px' : '0px'};
    border-top-left-radius: ${isFirst ? '6px' : '0px'};
    border-top-right-radius: ${isFirst ? '6px' : '0px'};
  `}

  background-color: ${({ hover, theme }) =>
    hover ? theme.palette.smoke.lighter : theme.palette.white};
`
