import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

type Props = {
  busy?: boolean
  icon: IconProp
  onClick: () => void
  title: ReactNode
}

export const IconButtonWithTooltip = ({
  busy,
  icon,
  onClick,
  title,
}: Props) => {
  const theme = useTheme()

  return (
    <Tooltip
      content={title}
      delay={300}
      placement="top"
      trigger={(triggerProps) => (
        <span {...triggerProps}>
          <IconButton busy={busy} compact onClick={onClick}>
            <FontAwesomeIcon
              color={theme.palette.primary.main}
              icon={icon}
              fixedWidth
            />
          </IconButton>
        </span>
      )}
    />
  )
}

////////////

const IconButton = styled(InnocuousButton)`
  min-width: 0;

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
  `}
`
