import { Fragment, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T, useLanguageContext } from '@/modules/Language'
import {
  HideFromPrintSpan,
  ListingTable,
  ListingTableSubheader,
  OnlyInPrintSpan,
  PrintLink,
} from '@/modules/Listing/common'
import { customerHooks } from '@/modules/Registry/Customer/hooks'
import { SalesTypeChip } from '@/modules/Sales'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { PrintSize } from '../../../types'
import { AgeGroupBreakdown, ParticipantsMode, RowSelector } from '../../common'
import { getParticipantsByRoom, getUniqueParticipants } from '../../utils'
import type {
  ArrivalsEvent,
  ArrivalsParticipant,
  ArrivalsRoomReservation,
} from '../useArrivalsForDate'
import { EventsTableParticipantRow } from './EventsTableParticipantRow'
import { EventsTableRoomRow } from './EventsTableRoomRow'

type Props = {
  data: Readonly<ArrivalsEvent[]>
  participantCounts: Record<string, Record<string, number>>
  printSize: PrintSize
  selection: string[]
  selectionEnabled: boolean
  targetDate: Moment
  participantsMode: ParticipantsMode
  toggleSelection: (target: string) => void
}

export const EventsTable = ({
  data,
  participantCounts,
  printSize,
  selection,
  selectionEnabled,
  targetDate,
  toggleSelection,
  participantsMode,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const headerRef = useRef<HTMLTableRowElement | null>(null)

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight)
    }
  }, [headerRef])

  const roomNumberSorter = (
    a: ArrivalsRoomReservation,
    b: ArrivalsRoomReservation
  ) =>
    a.request.room.number.localeCompare(b.request.room.number, language, {
      numeric: true,
    })

  return (
    <>
      {data.map((event) => {
        const participantsByRoom: Record<string, ArrivalsParticipant[]> =
          getParticipantsByRoom(event)

        const rooms =
          participantsMode === ParticipantsMode.All
            ? Object.values(event.roomsById)
            : Object.values(event.roomsById).filter(
                (room) =>
                  targetDate.isSame(room.request.checkIn.date) ||
                  targetDate.isBetween(
                    room.request.checkIn.date,
                    room.request.checkOut.date
                  )
              )

        const isSelected = selection.includes(event.id)
        const hideEventFromPrint = selectionEnabled && !isSelected

        const customer = event.customer
          ? customerHooks.useCustomerDetails({ customer: event.customer })
          : null

        return (
          <Fragment key={`arrivals-event-${event.id}`}>
            <ListingTable hideFromPrint={hideEventFromPrint}>
              <thead>
                <tr ref={headerRef}>
                  <th style={{ width: '14.2%' }}>
                    <Label>
                      <T>
                        Listings:ArrivalsAndDeparturesListing.field.participant
                      </T>
                    </Label>
                  </th>
                  <th style={{ width: '10%' }}>
                    <Label>
                      <T>
                        Listings:ArrivalsAndDeparturesListing.field.accommodationTarget
                      </T>
                    </Label>
                  </th>
                  <th colSpan={2} style={{ width: '31.4%' }}>
                    <Label>
                      <T>
                        Listings:ArrivalsAndDeparturesListing.field.participantProduct
                      </T>
                    </Label>
                  </th>
                  <th style={{ width: '8%' }}>
                    <Label>
                      <T>Listings:ArrivalsAndDeparturesListing.field.room</T>
                    </Label>
                  </th>
                  <th style={{ width: '8%' }}>
                    <Label>
                      <T>
                        Listings:ArrivalsAndDeparturesListing.field.enrollmentNumber
                      </T>
                    </Label>
                  </th>
                  <th colSpan={2} style={{ width: '20.4%' }}>
                    <Label>
                      <T>Listings:ArrivalsAndDeparturesListing.field.info</T>
                    </Label>
                  </th>
                  <th style={{ width: '8%' }}>
                    <Label>
                      <T>
                        Listings:ArrivalsAndDeparturesListing.field.duration
                      </T>
                    </Label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ListingTableSubheader
                  colSpan={9}
                  parts={[
                    {
                      content: (
                        <FlexRow>
                          {selectionEnabled && (
                            <RowSelector
                              isSelected={isSelected}
                              toggleSelection={() => toggleSelection(event.id)}
                            />
                          )}
                          {customer ? (
                            <PrintLink
                              to={`/registry/customers/${customer.customerNumber}`}
                              style={{
                                fontSize: theme.typography.fontSizeBase2,
                                maxWidth: '50mm',
                                wordBreak: 'break-word',
                              }}
                            >
                              {customer.displayName}
                            </PrintLink>
                          ) : (
                            <em>
                              <T>Customers:unset</T>
                            </em>
                          )}
                        </FlexRow>
                      ),
                      size: 2,
                    },
                    {
                      content: (
                        <>
                          <PrintLink
                            to={`/sales/details/${event.id}`}
                            style={{
                              fontSize: theme.typography.fontSizeBase2,
                              maxWidth: '50mm',
                              wordBreak: 'break-word',
                            }}
                          >
                            #{event.orderNumber} {event.name}
                          </PrintLink>
                          <SalesTypeChip
                            facet={event.facet}
                            format="short"
                            size="xs"
                            style={{ margin: `0 ${theme.spacing.gutter}` }}
                            type={event.type}
                          />

                          <HideFromPrintSpan>
                            {event?.paymentAgreement && (
                              <Tooltip
                                content={event.paymentAgreement.name}
                                delay={300}
                                trigger={(triggerProps) => (
                                  <span
                                    {...triggerProps}
                                    style={{
                                      fontSize: theme.typography.fontSizeBase2,
                                    }}
                                  >
                                    {event.paymentAgreement?.code}
                                  </span>
                                )}
                              />
                            )}
                          </HideFromPrintSpan>
                          <OnlyInPrintSpan style={{ whiteSpace: `nowrap` }}>
                            <PaymentAgreementLabel>
                              {event.paymentAgreement?.name || ''}
                            </PaymentAgreementLabel>
                          </OnlyInPrintSpan>
                        </>
                      ),
                      size: 2,
                    },
                    {
                      content: (
                        <AgeGroupBreakdown
                          counts={participantCounts[event.id]}
                          title={
                            <T
                              l10n={`Listings:ArrivalsAndDeparturesListing.arrivals.arrivals`}
                            />
                          }
                        />
                      ),
                      size: 5,
                    },
                  ]}
                  printSize={printSize}
                  stickyProps={
                    headerHeight ? { top: `${headerHeight}px` } : undefined
                  }
                />

                {!event.participants.length ? (
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      <em>
                        —{' '}
                        <T>
                          Listings:ArrivalsAndDeparturesListing.noParticipants
                        </T>{' '}
                        —
                      </em>
                    </td>
                  </tr>
                ) : (
                  getUniqueParticipants(
                    event.participants,
                    targetDate,
                    participantsMode
                  )
                    .sort(generateCompareFn(['lastName', 'firstName']))
                    .map((p) => (
                      <EventsTableParticipantRow
                        key={`arrivals-event-participant-${p.services[0].id}`}
                        participant={p}
                        roomsById={event.roomsById}
                      />
                    ))
                )}

                <ListingTableSubheader
                  colSpan={9}
                  noSpacer
                  parts={[
                    {
                      content: (
                        <SubheaderLabel>
                          <T>
                            Listings:ArrivalsAndDeparturesListing.field.room
                          </T>
                        </SubheaderLabel>
                      ),
                      size: 1,
                      width: '12%',
                    },
                    {
                      content: <FontAwesomeIcon icon="bed" size="sm" />,
                      size: 1,
                      width: '8%',
                    },
                    {
                      content: (
                        <SubheaderLabel>
                          <T>
                            Listings:ArrivalsAndDeparturesListing.field.accommodationTarget
                          </T>
                        </SubheaderLabel>
                      ),
                      size: 1,
                      width: '8%',
                    },
                    {
                      content: (
                        <SubheaderLabel>
                          <T>
                            Listings:ArrivalsAndDeparturesListing.field.bedChart
                          </T>
                        </SubheaderLabel>
                      ),
                      size: 3,
                      width: '38%',
                    },
                    {
                      content: (
                        <SubheaderLabel>
                          <T>
                            Listings:ArrivalsAndDeparturesListing.field.info
                          </T>
                        </SubheaderLabel>
                      ),
                      size: 2,
                      width: '22%',
                    },
                    {
                      content: (
                        <SubheaderLabel>
                          <T>
                            Listings:ArrivalsAndDeparturesListing.field.duration
                          </T>
                        </SubheaderLabel>
                      ),
                      size: 1,
                      width: '12%',
                    },
                  ]}
                  printSize={printSize}
                  stickyProps={
                    headerHeight ? { top: `${headerHeight}px` } : undefined
                  }
                />

                {!rooms.length ? (
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      <em>
                        — <T>Listings:ArrivalsAndDeparturesListing.noRooms</T> —
                      </em>
                    </td>
                  </tr>
                ) : (
                  rooms.sort(roomNumberSorter).map((room) => {
                    const participants = participantsByRoom[room.id] || []

                    return (
                      <EventsTableRoomRow
                        key={`arrivals-event-room-${room.id}`}
                        participants={getUniqueParticipants(
                          participants,
                          targetDate,
                          participantsMode,
                          'ROOM_BEDS'
                        )}
                        room={room}
                        targetDate={targetDate}
                      />
                    )
                  })
                )}
              </tbody>
            </ListingTable>
          </Fragment>
        )
      })}
    </>
  )
}

////////////

const SubheaderLabel = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const PaymentAgreementLabel = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    margin-left: ${theme.spacing.gutter};
  `}
`

const Label = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
