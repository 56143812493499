import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ListItem, ListItemSection } from '@/components/List'
import { Tooltip } from '@/components/Tooltip'
import { useGlobalIconsContext, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { Room, RoomFeature } from '../../../types'

type Props = {
  data: Room
}

export default function ListItemComponent({ data }: Props) {
  const theme = useTheme()

  const { checkIconValidity } = useGlobalIconsContext()

  return (
    <ListItem align="center">
      <ListItemSection>{data.id}</ListItemSection>
      <ListItemSection>{data.number}</ListItemSection>
      <ListItemSection>
        {data.beds} + {data.extraBeds}
      </ListItemSection>
      <ListItemSection>{data.floor}</ListItemSection>
      <ListItemSection>
        {[...data.features]
          .sort(generateCompareFn('name'))
          .map((feature: RoomFeature) => (
            <Tooltip
              content={feature.name}
              delay={300}
              key={feature.id}
              placement="top"
              trigger={(triggerProps) => (
                <span
                  {...triggerProps}
                  style={{ marginRight: theme.spacing.gutterSmall }}
                >
                  {checkIconValidity(feature.icon) ? (
                    <FontAwesomeIcon
                      color={theme.palette.text.light}
                      fixedWidth
                      icon={feature.icon as IconProp}
                    />
                  ) : (
                    <span style={{ fontWeight: 500 }}>{feature.shortName}</span>
                  )}
                </span>
              )}
            />
          ))}
      </ListItemSection>
      <ListItemSection>{data.building.name}</ListItemSection>
      <ListItemSection>{data.roomType.name}</ListItemSection>
    </ListItem>
  )
}
