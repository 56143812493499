import { IconButton } from '@/components/ExtraButtons'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { usePublishContext } from '../../../PublishState'
import { WebshopSettingsToken } from '../../../types'

type Props = {
  token: WebshopSettingsToken
}

export const RemoveLinkButton = ({ token: { token } }: Props) => {
  const { palette } = useTheme()
  const { readOnly, removeWebshopToken, salesId } = usePublishContext()

  return (
    <Tooltip
      content={<T>Publish:Permissions.link.remove</T>}
      delay={200}
      placement="top"
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <IconButton
            disabled={readOnly}
            color="transparent"
            icon="trash"
            onClick={() => removeWebshopToken({ salesId, tokenId: token })}
            style={{ color: palette.danger.main }}
          />
        </div>
      )}
    />
  )
}
