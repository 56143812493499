import { Fragment, useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import {
  OtherPaymentMethod,
  Payment,
  PaymentDetails as PaymentDetailsType,
  PaymentOtherDetails as OtherDetailsType,
  PaymentVoucherDetails as VoucherDetailsType,
} from '@/modules/Order/types'
import { formatCurrency } from '@/utils/currency'

import { PaymentOperation, PaymentType } from '~generated-types'

import { OtherPaymentModal } from '../OtherPaymentModal'
import { ReferencePaymentModal } from '../ReferencePaymentModal'
import { Cell, PaymentDetails, SectionWithTooltip } from './components'

type Props = {
  invoiceId: string
  isFirst: boolean
  openPaymentsModal: () => void
  orderId: string
  payment: Payment
}

export const PaymentRow = ({
  invoiceId,
  isFirst,
  openPaymentsModal,
  orderId,
  payment,
}: Props) => {
  const { amount, auditLog, date, details, lifecycle, type } = payment

  const [isOtherPaymentModalOpen, setOtherPaymentModalOpen] =
    useState<boolean>(false)
  const [isReferencePaymentModalOpen, setReferencePaymentModalOpen] =
    useState<boolean>(false)

  const readOnly = !lifecycle.validatedActions.find(
    ({ action }) => action === PaymentOperation.UpdateManualPayment
  )?.valid

  const paymentColor =
    type === PaymentType.RefundInvoice || amount < 0 ? 'danger' : 'primary'

  const handleOpenPayment = () => {
    switch (type) {
      case PaymentType.Other:
        return setOtherPaymentModalOpen(true)
      case PaymentType.ReferenceTransfer:
        return setReferencePaymentModalOpen(true)
      case PaymentType.Cash:
      case PaymentType.CreditCard:
      case PaymentType.GiftCard:
      case PaymentType.Voucher:
        return openPaymentsModal()
      default:
        return null
    }
  }

  return (
    <Fragment>
      {!isFirst && <Separator />}

      <Row
        alignItems="stretch"
        readOnly={readOnly}
        onClick={() => !readOnly && handleOpenPayment()}
      >
        <PaymentTypeChipWrapper>
          <PaymentTypeChip color={paymentColor} size="sm" variant="outlined">
            {getPaymentTypeLabel(type, details)}
          </PaymentTypeChip>
        </PaymentTypeChipWrapper>

        <SectionWithTooltip
          content={moment(auditLog.createdAt).format('dd, D.M.YYYY, HH:mm')}
          width={26}
          icon={['far', 'calendar']}
          title={<T>Orders:Payments.field.creationDate</T>}
        />

        {details ? (
          <PaymentDetails date={date} details={details} type={type} />
        ) : (
          <Cell flex={1} style={{ alignSelf: 'stretch' }} />
        )}

        <Cell align="end" width={16}>
          <FontWeight semiBold>{formatCurrency(amount)} €</FontWeight>
        </Cell>
      </Row>

      <ModalContainer
        isOpen={isOtherPaymentModalOpen}
        onClose={() => setOtherPaymentModalOpen(false)}
        modal={
          <OtherPaymentModal
            invoiceId={invoiceId}
            method={
              amount < 0
                ? OtherPaymentMethod.Refund
                : OtherPaymentMethod.Payment
            }
            onClose={() => setOtherPaymentModalOpen(false)}
            orderId={orderId}
            payment={payment}
          />
        }
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />

      <ModalContainer
        isOpen={isReferencePaymentModalOpen}
        onClose={() => setReferencePaymentModalOpen(false)}
        modal={
          <ReferencePaymentModal
            invoiceId={invoiceId}
            onClose={() => setReferencePaymentModalOpen(false)}
            orderId={orderId}
            payment={payment}
          />
        }
        referenceElement={() => null}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </Fragment>
  )
}

///////

const getPaymentTypeLabel = (
  type: PaymentType,
  details: PaymentDetailsType
) => {
  switch (type) {
    case PaymentType.Other:
      return (
        (details as OtherDetailsType).paymentType?.name ?? (
          <T>Orders:Payments.type.OTHER</T>
        )
      )
    case PaymentType.Voucher:
      return (details as VoucherDetailsType).type.name
    default:
      return <T>{`Orders:Payments.type.${type}`}</T>
  }
}

const PaymentTypeChip = styled(Chip)`
  width: 100%;
  border-color: transparent;
  text-align: unset;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const PaymentTypeChipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 4px 4px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(18)}rem;
  `}
`

const Row = styled(FlexRow)<{ readOnly: boolean }>`
  cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};

  &:hover {
    ${({ readOnly, theme }) => css`
      background: ${readOnly
        ? 'transparent'
        : theme.palette.primary.extraLighter};
    `}
  }
`

const Separator = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.dark};
  `}
`
