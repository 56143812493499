import { ReactNode } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Tooltip } from '@/components/Tooltip'

type ActionButtonProps = {
  content: ReactNode | string
  icon: IconProp
  iconColor: string
  onClick: () => void | Promise<void | undefined>
}

export const ActionButton = ({
  content,
  icon,
  iconColor,
  onClick,
}: ActionButtonProps) => (
  <Tooltip
    content={content}
    delay={300}
    placement="left"
    trigger={(triggerProps) => (
      <span {...triggerProps}>
        <IconButton onClick={onClick}>
          <FontAwesomeIcon color={iconColor} icon={icon} size="sm" />
        </IconButton>
      </span>
    )}
  />
)

export const ActionButtonWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}
`

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const IconWrapper = styled.div`
  text-align: end;

  ${({ theme }) => css`
    width: ${theme.spacing.gutterBig};
  `}
`

export const EditButton = styled(IconButton)`
  justify-content: space-between;
  flex: 1;
  height: 100%;
  min-height: 30px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-left: -${theme.spacing.gu(1)}rem;
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem;
    width: calc(100% + ${theme.spacing.gu(1)}rem);
  `}
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.guPx(1) - 2}px;
  `}
`

export const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
