import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

type PaymentTypeButtonProps = {
  disabled?: boolean
  isSelected?: boolean
}

export const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const buttonStyle = css<PaymentTypeButtonProps>`
  display: flex;
  align-items: center;

  border-radius: 6px;
  cursor: pointer;
  height: 42px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
    color: ${theme.palette.text.lighter};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const buttonActionsStyle = css<PaymentTypeButtonProps>`
  :hover:enabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  :active:enabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const buttonDisabledStyle = css<PaymentTypeButtonProps>`
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`

const buttonSelectedStyle = css<PaymentTypeButtonProps>`
  ${({ isSelected, theme }) =>
    isSelected &&
    `
    border-color: ${theme.palette.primary.dark};
    color: ${theme.palette.primary.dark};
  `}
`

export const Button = styled.button<PaymentTypeButtonProps>`
  ${buttonStyle}
  ${buttonDisabledStyle}
  ${buttonActionsStyle}

  ${({ theme }) => css`
    background: ${theme.palette.white};
    padding: 0 0 0 ${theme.spacing.gu(1.5)}rem;
    width: calc(50% - ${theme.spacing.gu(1) / 2}rem);
  `}

  ${buttonSelectedStyle}
`

export const Dropdown = styled.div<PaymentTypeButtonProps>`
  ${buttonStyle}
  ${buttonDisabledStyle}
  ${buttonSelectedStyle}
`

export const DropdownButton = styled.button<PaymentTypeButtonProps>`
  ${buttonStyle}

  flex: 1;

  border-radius: 6px 0 0 6px;
  border: none;
  height: 100%;
  margin: 0;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.dark};
    padding: 0 0 0 ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ disabled }) => disabled && `cursor: not-allowed;`}
  
  ${buttonSelectedStyle}
  ${buttonActionsStyle}
`

export const DropdownArrowButton = styled(DropdownButton)`
  justify-content: center;
  flex: none;

  border: none;
  border-radius: 0 6px 6px 0;
  padding: 0;
  width: 32px;
`

export const DropdownWrapper = styled.div`
  ${({ theme }) => css`
    width: calc(50% - ${theme.spacing.gu(1) / 2}rem);
  `}
`

export const Label = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

export const LabelTruncate = styled.span`
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(14)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

export const OptionButton = styled(FlexRow).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
}))<{
  disabled?: boolean
  isSelected?: boolean
}>`
  cursor: pointer;
  height: 30px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1.5)}rem;
  `}

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.palette.primary.main};
      color: ${theme.palette.white};
    `}
  
  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${theme.palette.smoke.lighter};
      opacity: 0.5;
    `}

  &:hover {
    ${({ disabled, isSelected, theme }) =>
      !isSelected &&
      !disabled &&
      css`
        background: ${theme.palette.primary.extraLight};
      `}
  }

  &:active {
    ${({ disabled, isSelected, theme }) =>
      !isSelected &&
      !disabled &&
      css`
        background: ${darken(0.05, theme.palette.primary.extraLight)};
      `}
  }
`

export const TerminalIndicator = styled.div<{ isAvailable: boolean }>`
  width: 7px;
  height: 7px;
  border-radius: 50%;

  ${({ isAvailable, theme }) => css`
    background: ${isAvailable
      ? theme.palette.success.main
      : theme.palette.danger.main};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
