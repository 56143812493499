import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css, useTheme } from 'styled-components'

import { InnocuousButton } from '@/components/ExtraButtons'
import { InlineModal, InlineModalContent } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'

import {
  SalesParticipantCountsQuery,
  SalesParticipantCountsQueryVariables,
} from '~generated-types'

import ParticipantsListQueries from '../../ParticipantsList.queries'

type Props = {
  salesId: string
}
type AgeCategory =
  SalesParticipantCountsQuery['sales']['participantCounts']['byAgeCategory'][0]

export const ParticipantsAgeCategory = ({ salesId }: Props) => {
  const { palette, spacing } = useTheme()
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const [ageCategories, setAgeCategories] = useState<AgeCategory[] | null>(null)

  const [loadParticipantCounts, { data, loading }] = useLazyQuery<
    SalesParticipantCountsQuery,
    SalesParticipantCountsQueryVariables
  >(ParticipantsListQueries.PARTICIPANT_COUNTS, {
    fetchPolicy: 'cache-and-network',
    variables: { id: salesId },
  })

  useEffect(() => {
    if (!data) return

    const {
      sales: {
        participantCounts: { byAgeCategory },
      },
    } = data

    setAgeCategories(byAgeCategory)
  }, [data])

  return (
    <>
      <Tooltip
        content={
          !ageCategories?.length && isModalOpen && !loading ? (
            <T>ParticipantsList:ageCategorySummary.noCategory</T>
          ) : (
            <T>ParticipantsList:ageCategorySummary.title</T>
          )
        }
        delay={300}
        placement="top"
        trigger={(triggerProps) => (
          <ModalContainer
            isOpen={isModalOpen && !!ageCategories?.length}
            onClose={() => {
              setModalOpen(false)
              triggerProps.onMouseOut()
            }}
            placement={'bottom-start'}
            modal={
              <InlineModal>
                <InlineModalContent
                  style={{ padding: `${spacing.gu(0.5)}rem 0` }}
                >
                  {ageCategories?.map(({ ageCategory, count }) => (
                    <ContentContainer key={`age-category-${ageCategory?.key}`}>
                      <AgeLabel>
                        {ageCategory?.name || (
                          <T>ParticipantsList:ageCategorySummary.notSelected</T>
                        )}
                      </AgeLabel>
                      <AgeCount>{count}</AgeCount>
                    </ContentContainer>
                  ))}
                </InlineModalContent>
              </InlineModal>
            }
            referenceElement={({ ref }) => (
              <ButtonWrapper ref={ref}>
                <div {...triggerProps}>
                  <InnocuousButton
                    onClick={() => {
                      loadParticipantCounts()
                      setModalOpen(true)
                      triggerProps.onMouseOut()
                    }}
                  >
                    {!loading ? (
                      <FontAwesomeIcon icon="users" />
                    ) : (
                      <ReactLoading
                        color={palette.smoke.dark}
                        height={18}
                        type="spin"
                        width={18}
                      />
                    )}
                  </InnocuousButton>
                </div>
              </ButtonWrapper>
            )}
          />
        )}
      />
    </>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    width: ${theme.spacing.gu(5)}rem;
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const ContentContainer = styled.div`
  display: flex;

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(26)}rem;
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}

  &:not(:last-child) {
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const AgeLabel = styled.span`
  width: 80%;
`

const AgeCount = styled.span`
  width: 20%;
  text-align: center;
`
