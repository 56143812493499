import { CSSProperties } from 'react'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Placement, Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { Palette, useTheme } from '@/theme'

import { IssueLevel as Level } from '~generated-types'

type Props = {
  code: number
  level: Level
  placement?: Placement
  size?: SizeProp
  style?: CSSProperties
}

export const ValidationIndicator = ({
  code,
  level,
  placement = 'top',
  size = '1x',
  style,
}: Props) => {
  const { palette } = useTheme()

  return (
    <Tooltip
      color={getTooltipColor(level)}
      content={<T>{`IssueCodes:${code}`}</T>}
      delay={300}
      maxWidth={150}
      placement={placement}
      trigger={(triggerProps) => (
        <span {...triggerProps}>
          <Icon
            color={getIconColor(level, palette)}
            fixedWidth
            icon={level === Level.Info ? 'circle-info' : 'triangle-exclamation'}
            size={size}
            style={style}
          />
        </span>
      )}
    />
  )
}

//////

const getIconColor = (level: Level, palette: Palette) => {
  switch (level) {
    case Level.Error:
      return palette.danger.main
    case Level.Warning:
      return palette.warning.dark
    default:
      return palette.text.lighter
  }
}

const getTooltipColor = (level: Level) => {
  switch (level) {
    case Level.Error:
      return 'danger'
    case Level.Warning:
      return 'warning'
    default:
      return 'default'
  }
}

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
