import { CSSProperties } from 'react'
import React from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { H4 } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import useProductLinesTable from './useProductLinesTable'

export interface ProductLinesTableProps {
  invoiceId: string
}

export const ProductLinesTable = ({ invoiceId }: ProductLinesTableProps) => {
  const theme = useTheme()

  const { data, error, loading } = useProductLinesTable({ invoiceId })

  const currencyCellStyles: CSSProperties = { textAlign: 'right' }

  if (loading) {
    return (
      <FlexRow>
        <LoadingPlaceholder flex={1} size="lg" />
      </FlexRow>
    )
  }

  if (error || !data) {
    return (
      <FlexRow>
        <PlaceholderLabel>
          — <T>Reports:ProductLines.error</T> —
        </PlaceholderLabel>
      </FlexRow>
    )
  }

  if (!data.rows.length) {
    return (
      <FlexRow>
        <PlaceholderLabel>
          — <T>Reports:ProductLines.empty</T> —
        </PlaceholderLabel>
      </FlexRow>
    )
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <T>Reports:ProductLines.field.product</T>
          </th>
          <th style={currencyCellStyles}>
            <T>Reports:ProductLines.field.unitPrice</T> [€]
          </th>
          <th style={currencyCellStyles}>
            <T>Reports:ProductLines.field.quantity</T>
          </th>
          <th style={currencyCellStyles}>
            <T>Reports:ProductLines.field.vat</T> [€]
          </th>
          <th style={currencyCellStyles}>
            <T>Reports:ProductLines.field.total</T> [€]
          </th>
        </tr>
      </thead>
      <tbody>
        {data.rows.map(
          ({ id, name, packetTotal, packetUnitPrice, products, quantity }) => (
            <React.Fragment key={`invoice-line-${id}`}>
              <tr key={`invoice-line-${id}-main`}>
                <td>{name}</td>
                <td style={currencyCellStyles}>
                  {formatCurrency(packetUnitPrice.amountVatIncluded)}
                </td>
                <td style={currencyCellStyles}>{quantity}</td>
                <td style={currencyCellStyles}>
                  {formatCurrency(packetTotal.vatAmount)}
                </td>
                <td style={currencyCellStyles}>
                  {formatCurrency(packetTotal.amountVatIncluded)}
                </td>
              </tr>
              {products.length > 1 &&
                products.map(({ id, name, pricesPerPacket, totalPrice }) => (
                  <tr className="productRow" key={`invoice-line-product-${id}`}>
                    <td>
                      <span
                        style={{
                          display: 'inline-block',
                          marginRight: `${theme.spacing.gu(1)}rem`,
                          textAlign: 'right',
                          width: 24,
                        }}
                      >
                        —
                      </span>
                      {name}
                    </td>
                    <td style={currencyCellStyles}>
                      {pricesPerPacket
                        ? formatCurrency(pricesPerPacket.amountVatIncluded)
                        : '–'}
                    </td>
                    <td />
                    <td style={currencyCellStyles}>
                      {totalPrice ? formatCurrency(totalPrice.vatAmount) : '–'}
                    </td>
                    <td style={currencyCellStyles}>
                      {totalPrice
                        ? formatCurrency(totalPrice.amountVatIncluded)
                        : '–'}
                    </td>
                  </tr>
                ))}
            </React.Fragment>
          )
        )}
        <tr className="totalRow">
          <td colSpan={3} style={currencyCellStyles}>
            <T>Reports:ProductLines.total</T>:
          </td>
          <td style={currencyCellStyles}>
            {formatCurrency(data.total.vatAmount)}
          </td>
          <td style={currencyCellStyles}>
            {formatCurrency(data.total.amountVatIncluded)}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default ProductLinesTable

////////////

export const PlaceholderLabel = styled(H4)`
  flex: 1;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  &&& {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(3)}rem 0;
    `}
  }
`

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  width: 100%;

  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}

  & td,
  & th {
    ${({ theme }) => css`
      color: ${theme.palette.text.dark};
      padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    `}
  }

  & th {
    font-weight: 700;
    text-align: left;

    ${({ theme }) => css`
      border-bottom: solid 2px ${theme.palette.text.dark};
    `}
  }

  & > tbody > tr:not(:last-child) > td {
    ${({ theme }) => css`
      border-bottom: solid 1px ${theme.palette.text.dark};
    `}
  }

  & > tbody > tr.productRow > td {
    font-weight: 300;
    font-style: italic;

    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeSmaller};
    `}
  }

  & > tbody > tr.totalRow > td {
    font-weight: 700;

    ${({ theme }) => css`
      border-top: solid 1px ${theme.palette.text.dark};
      font-size: ${theme.typography.fontSizeBase};
    `}
  }
`
