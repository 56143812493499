import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { orderContexts } from '@/modules/Order/contexts'
import { orderServices } from '@/modules/Order/services'
import { getIssueColor, useTheme } from '@/theme'

import { IssueLevel, PaymentState } from '~generated-types'

type Props = {
  invoiceId: string
  orderId: string
  paymentId: string
}

export const TerminalWaitingSection = ({
  invoiceId,
  orderId,
  paymentId,
}: Props) => {
  const { palette } = useTheme()

  const { cancelExternalPayment, refreshPayment } =
    orderServices.invoiceService()
  const { setObservablePayment } = orderContexts.useObservablePayment()

  const [refreshProcessing, setRefreshProcessing] = useState<boolean>(false)
  const [cancelProcessing, setCancelProcessing] = useState<boolean>(false)

  const handleCancelPayment = () => {
    setCancelProcessing(true)

    cancelExternalPayment(invoiceId, orderId, paymentId).then(
      (data) => !data && setCancelProcessing(false)
    )
  }

  const handleRefreshPayment = () => {
    setRefreshProcessing(true)

    refreshPayment(invoiceId, orderId, paymentId)
      .then((payment) => {
        if (payment) {
          const isPaymentPaid =
            payment.state === PaymentState.Paid ||
            payment.state === PaymentState.PaidPending

          setObservablePayment(!isPaymentPaid ? payment : null)
        }
      })
      .finally(() => setRefreshProcessing(false))
  }

  return (
    <Wrapper alignItems="center" justifyContent="space-between">
      <Title>
        <T>Orders:Payments.terminal.waitingMessage</T>
      </Title>

      <ReactLoading
        type="bubbles"
        color={palette.warning.dark}
        height={40}
        width={40}
      />

      <Actions justifyContent="space-between">
        <RefreshButton
          compact
          disabled={refreshProcessing}
          noNudge
          onClick={handleRefreshPayment}
        >
          <IconWrapper>
            {refreshProcessing ? (
              <ReactLoading
                type="spin"
                color={palette.warning.dark}
                height={14}
                width={14}
              />
            ) : (
              <FontAwesomeIcon icon="arrows-rotate" size="sm" />
            )}
          </IconWrapper>
          <T>Orders:Payments.terminal.refresh</T>
        </RefreshButton>

        <CancelButton
          compact
          disabled={cancelProcessing}
          noNudge
          onClick={handleCancelPayment}
        >
          <IconWrapper>
            {cancelProcessing ? (
              <ReactLoading
                type="spin"
                color={palette.warning.dark}
                height={14}
                width={14}
              />
            ) : (
              <FontAwesomeIcon icon="times" size="sm" />
            )}
          </IconWrapper>
          <T>common:action.cancel</T>
        </CancelButton>
      </Actions>
    </Wrapper>
  )
}

///////

const Actions = styled(FlexRow)`
  width: 100%;
`

const IconWrapper = styled.div`
  width: 14px;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const CancelButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}
`

const RefreshButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    color: ${theme.palette.warning.dark};
  `}
`

const Title = styled.span`
  font-weight: 500;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.warning.dark};
    font-size: ${theme.typography.fontSizeBase2};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem 0;
  `}
`

const Wrapper = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    background: ${getIssueColor(IssueLevel.Warning, theme.palette).background};
    border: 1px solid ${theme.palette.warning.dark};
    height: calc(42px * 2 + ${theme.spacing.gu(8)}rem + 1px);
    margin: 0 ${theme.spacing.gutter} ${theme.spacing.gutter};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
