import React from 'react'
import { Decimal } from 'decimal.js'
import styled from 'styled-components/macro'

import { T } from '@/modules/Language'
import {
  ListingTable,
  ListingTableRowSpacer,
  ListingTableSubheader,
} from '@/modules/Listing/common'
import { PrintSize } from '@/modules/Listing/types'
import { generateCompareFn } from '@/utils/arrays'
import { formatCurrency } from '@/utils/currency'

import { PaymentType } from '~generated-types'

import type {
  PaymentAmountsByType,
  PointOfSaleDetails,
} from '../usePaymentsForInterval'
import { PaymentCells } from './PaymentCells'
import { PaymentsTableRow } from './PaymentsTableRow'

export interface PaymentsTableProps {
  payments: PointOfSaleDetails[]
  printSize: PrintSize
  totalAmounts: PaymentAmountsByType
  voucherProviders: string[]
}

export const PaymentsTable = ({
  payments,
  printSize,
  totalAmounts,
  voucherProviders,
}: PaymentsTableProps) => {
  const colSpan = 8 + voucherProviders.length

  const totalAmountsCombined = [
    totalAmounts[PaymentType.Cash],
    totalAmounts[PaymentType.CreditCard],
    totalAmounts[PaymentType.GiftCard],
    ...Object.values(totalAmounts.vouchersByProvider),
  ].reduce((acc: Decimal, val) => acc.plus(val), new Decimal(0))

  return (
    <ListingTable collapsePaddingRight printSize={printSize}>
      <thead>
        <tr>
          <TH>
            <T>Reports:Payments.field.invoiceNr</T>
          </TH>
          <TH className="show-left-border" style={{ textAlign: 'right' }}>
            <T>enums:paymentType.CASH</T>
          </TH>
          <TH style={{ textAlign: 'right' }}>
            <T>enums:paymentType.CREDIT_CARD</T>
          </TH>
          <TH style={{ textAlign: 'right' }}>
            <T>enums:paymentType.GIFT_CARD</T>
          </TH>
          {voucherProviders.map((provider) => (
            <TH
              key={`voucher-provider-header-${provider}`}
              style={{ textAlign: 'right' }}
            >
              {provider}
            </TH>
          ))}
          <TH className="show-left-border">
            <T>Reports:Payments.field.seller</T>
          </TH>
          <TH>
            <T>Reports:Payments.field.customer</T>
          </TH>
          <TH>
            <T>Reports:Payments.field.sales</T>
          </TH>
          <ActionButtonTH />
        </tr>
      </thead>
      <tbody>
        {payments.map(
          ({ amounts, amountsTotal, invoicesById, pointOfSale }) => (
            <React.Fragment key={`point-of-sales-payments-${pointOfSale.id}`}>
              <ListingTableSubheader colSpan={colSpan} printSize={printSize}>
                {pointOfSale.name}
              </ListingTableSubheader>

              {Object.values(invoicesById)
                .sort(generateCompareFn('invoice.invoiceNumber'))
                .map((invoice) => (
                  <PaymentsTableRow
                    colSpan={colSpan}
                    data={invoice}
                    key={`point-of-sales-payments-invoice-${invoice.invoice?.id}`}
                    voucherProviders={voucherProviders}
                  />
                ))}

              <tr className="summaryrow">
                <td>
                  <Uppercase>
                    <T>Reports:Payments.field.total</T>
                  </Uppercase>
                </td>
                <PaymentCells
                  amounts={amounts}
                  notes={null}
                  voucherProviders={voucherProviders}
                />
                <td className="show-left-border" style={{ textAlign: 'right' }}>
                  {formatCurrency(amountsTotal.toNumber())}
                </td>
                <td className="show-left-border" colSpan={3} />
              </tr>
            </React.Fragment>
          )
        )}

        <ListingTableRowSpacer colSpan={colSpan} />
        <tr className="summaryrow">
          <td>
            <Uppercase>
              <T>Reports:Payments.field.all</T>
            </Uppercase>
          </td>
          <PaymentCells
            amounts={totalAmounts}
            notes={null}
            voucherProviders={voucherProviders}
          />
          <td className="show-left-border" style={{ textAlign: 'right' }}>
            {formatCurrency(totalAmountsCombined.toNumber())}
          </td>
          <td className="show-left-border" colSpan={3} />
        </tr>
      </tbody>
    </ListingTable>
  )
}

export default PaymentsTable

////////////

const TH = styled.th`
  white-space: nowrap;
`

const ActionButtonTH = styled(TH)`
  width: 30px;

  @media print {
    &&& {
      border-right-width: 0;
      padding-left: 0;
      padding-right: 0;
      width: 0;
    }
  }
`

const Uppercase = styled.span`
  text-transform: uppercase;
`
